/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import './icons';

.error-message {
    padding: 0.25rem 0.75rem;
    color: #a94442;
    background-color: #f2dede;
    border: 1px solid #ebcccc;
}