@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";


// Bootstrap overrides necessary with the upgrade from boostrap 3 to 5
.nav-item {
  --bs-nav-link-color: #f46f34;
  --bs-nav-link-padding-y: 10px;
  --bs-nav-link-padding-x: 15px;
}

.navbar {
  padding: 0 var(--bs-navbar-padding-x);
}

.nav-item:hover {
  background-color: #eee;
}

.btn-info {
  --bs-btn-disabled-color: #fff;
}

.table > :not(caption) > * > * {
  background-color: unset;
  color: unset;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color:#eee;
  opacity:1
}

// END: Bootstrap overrides necessary with the upgrade from boostrap 3 to 5
