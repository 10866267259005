
.icon-spin {
    display: inline-block;
    -moz-animation: spin 2s infinite linear;
    -o-animation: spin 2s infinite linear;
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
}

.no-border-radius {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-justify {
    text-align: justify;
}

.text-line-through {
    text-decoration: line-through;
}

.box-heading {
    font-weight: bold;
    margin-bottom: 15px;
}

.center-align {
    vertical-align: middle;
}

.cursor {
    cursor: pointer;
}

.clickable {
    cursor: pointer;
    color:blue;
    text-decoration:underline;
}

.full-width {
    width: 100% !important;
}

.padding-left-sm,
.padding-x-sm {
    padding-left: 5px !important;
}

.padding-right-sm,
.padding-x-sm {
    padding-right: 5px !important;
}

.padding-left,
.padding-x {
    padding-left: 10px !important;
}

.padding-right,
.padding-x {
    padding-right: 10px !important;
}

.padding-top-sm,
.padding-y-sm {
    padding-top: 5px !important;
}

.padding-bottom-sm,
.padding-y-sm {
    padding-bottom: 5px !important;
}

.padding-top,
.padding-y {
    padding-top: 10px !important;
}

.padding-bottom,
.padding-y {
    padding-bottom: 10px !important;
}


.no-padding {
    padding: 0 !important;
}

.no-padding-y {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.no-padding-x {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-margin-top,
.no-margin-y {
    margin-top: 0 !important;
}

.no-margin-bottom,
.no-margin-y {
    margin-bottom: 0 !important;
}

.no-float {
    float: none !important;
}

.no-border {
    border: none !important;
}

/* Works with any screen size */
.col-12 {
    width: 100%
}

.col-11 {
    width: 91.66666666666667%
}

.col-10 {
    width: 83.33333333333334%
}

.col-9 {
    width: 75%
}

.col-8 {
    width: 66.66666666666667%
}

.col-7 {
    width: 58.333333333333336%
}

.col-6 {
    width: 50%
}

.col-5 {
    width: 41.66666666666667%
}

.col-4 {
    width: 33.333333333333336%
}

.col-3 {
    width: 25%
}

.col-2 {
    width: 16.666666666666668%
}

.col-1 {
    width: 8.333333333333334%
}

/* old style */

.cardFilter {
    width: 130px;
    font-size: 10px;
}

.large-card-filter .cardFilter {
    width: 160px;
}

.ng-select.cardFilter, .ng-select-container  {
    min-height:20px !important;
    height:30px !important;
    max-height:30px !important;
  }

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: white !important;
    font-size: 10px !important;
  }

.topmargin7 {
    margin-top: 7px;
}

.browsehappy {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}


* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

    *:before, *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

html {
    font-size: 62.5%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
}

body {
    font-size: 13px;
    line-height: 1.42857143;
    color: #000000;
    background-color: #f0f2f5;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
}

input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

a {
    color: #f46f34;
    text-decoration: none;
}

    a:hover, a:focus {
        color: #0a819c;
        text-decoration: none;
    }

    a:focus {
        outline: thin dotted #333;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }

figure {
    margin: 0;
}

img {
    vertical-align: middle;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.img-rounded {
    border-radius: 6px;
}

.img-thumbnail {
    padding: 4px;
    line-height: 1.42857143;
    background-color: #f0f2f5;
    border: 1px solid #dddddd;
    border-radius: 4px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    max-width: 100%;
    height: auto;
}

hr {
    margin-top: 18px;
    margin-bottom: 18px;
    border: 0;
    border-top: 1px solid #eeeeee;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.require {
    color: #ff0000;
}

#wrapper {
    width: 100%;
    min-height: 100%;
    background-color: #1F1F1F;
}

.page-wrapper {
    min-height: 100%;
    background-color: 	#808080;
    -webkit-transition: 0.3s all linear;
    -moz-transition: 0.3s all linear;
    -ms-transition: 0.3s all linear;
    -o-transition: 0.3s all linear;
    transition: 0.3s all linear;
}

.enterprise-card{
  color: rgb(206, 128, 45);
}


.page-form .checkbox-list {
    padding-top: 7px;
}

.navbar-static-top .navbar-header {
    width: 250px;
    height: 100%;
    background: #fff;
    text-align: center;
    overflow: hidden;
    /* Transition should be the same as sidebar has */
    -webkit-transition: 0.3s width linear;
    -moz-transition: 0.3s width linear;
    -ms-transition: 0.3s width linear;
    -o-transition: 0.3s width linear;
    transition: 0.3s width linear;
}

.left-side-collapsed .navbar-static-top .navbar-header {
    width: 55px;
    overflow: hidden;
}

    .left-side-collapsed .navbar-static-top .navbar-header .navbar-brand {
        width: 40px;
        overflow: hidden;
        margin: 0 auto;
        float: none;
    }

.navbar-static-top .navbar-header .navbar-brand {
    font-size: 20px;
    line-height: 20px;
    color: #fff;
    height: 100%;
    width: 100%;
    padding: 5px 0;
}

    .navbar-static-top .navbar-header .navbar-brand .logotype {
        /*  Height Note:
            150 origin img size,
            4 - to keep size even to origin.
            It's blurry othervise
    */
        height: calc(150px / 4);
    }

.topbar-main {
    display: block;
    height: 100%;
    background: #0074bd;
}

    .topbar-main #menu-toggle {
        float: left;
        padding: 15px 20px;
        background: transparent;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        border-radius: 0;
        color: #fff;
    }

        .topbar-main #menu-toggle:hover, #topbar .topbar-main #menu-toggle:focus {
            color: #1b4283;
        }

        .topbar-main #menu-toggle i {
            font-size: 25px;
        }

#topbar .topbar-main ul.navbar.navbar-nav {
    display: none;
}

    #topbar .topbar-main ul.navbar.navbar-nav.horizontal-menu {
        display: block;
    }

    #topbar .topbar-main ul.navbar.navbar-nav li.active a, #topbar .topbar-main ul.navbar.navbar-nav li:hover a, #topbar .topbar-main ul.navbar.navbar-nav li.open a {
        background: #fff;
        color: #999;
    }

    #topbar .topbar-main ul.navbar.navbar-nav li.mega-menu-dropdown {
        position: static;
    }

        #topbar .topbar-main ul.navbar.navbar-nav li.mega-menu-dropdown.mega-menu-full .dropdown-menu {
            left: 20px;
            right: 20px;
        }

        #topbar .topbar-main ul.navbar.navbar-nav li.mega-menu-dropdown > .dropdown-menu {
            left: auto;
            width: auto;
        }

            #topbar .topbar-main ul.navbar.navbar-nav li.mega-menu-dropdown > .dropdown-menu .mega-menu-content {
                padding: 10px;
                margin: 0;
            }

                #topbar .topbar-main ul.navbar.navbar-nav li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu {
                    width: auto !important;
                    padding: 0px 15px !important;
                    margin: 0 !important;
                    border-right: 1px solid #eeeeee;
                }

                    #topbar .topbar-main ul.navbar.navbar-nav li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu:last-child {
                        border-right: 0;
                    }

                    #topbar .topbar-main ul.navbar.navbar-nav li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li {
                        padding: 2px !important;
                        margin: 0 !important;
                        list-style: none;
                    }

                        #topbar .topbar-main ul.navbar.navbar-nav li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li h3 {
                            color: #555555;
                            margin-top: 10px;
                            padding-left: 5px;
                            font-size: 15px;
                            font-weight: normal;
                        }

                        #topbar .topbar-main ul.navbar.navbar-nav li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li a {
                            padding: 5px !important;
                            margin: 0 !important;
                            font-weight: normal;
                            display: block;
                        }

                            #topbar .topbar-main ul.navbar.navbar-nav li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li a:hover {
                                background: #f0f0f0;
                            }

                #topbar .topbar-main ul.navbar.navbar-nav li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .document-demo .mega-menu-submenu {
                    border-right: none;
                }

                    #topbar .topbar-main ul.navbar.navbar-nav li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .document-demo .mega-menu-submenu li a {
                        text-align: center;
                        padding: 30px 5px !important;
                    }

                        #topbar .topbar-main ul.navbar.navbar-nav li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .document-demo .mega-menu-submenu li a:hover {
                            background: transparent;
                        }

                        #topbar .topbar-main ul.navbar.navbar-nav li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .document-demo .mega-menu-submenu li a i {
                            font-size: 50px;
                            display: block;
                            width: 100%;
                            margin-bottom: 20px;
                        }

    #topbar .topbar-main ul.navbar.navbar-nav li a {
        color: #fff;
    }

#topbar #topbar-search {
    width: 250px;
    float: left;
}

    #topbar #topbar-search i {
        font-size: 20px;
        margin: 0;
        top: 17px;
        right: 12px;
        color: #fff;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    #topbar #topbar-search input {
        padding: 15px 30px !important;
        height: 100%;
        border: 0 !important;
        border-radius: 0;
        background: #3a484f;
    }

.topbar-main .navbar-top-links li {
    display: inline-block;
}

    .topbar-main .navbar-top-links li.open > a {
        background: #1b4283 !important;
        color: #fff !important;
    }

        .topbar-main .navbar-top-links li.open > a:hover, .topbar-main .navbar-top-links li.open > a:focus {
            background: #1b4283 !important;
        }

        .topbar-main .navbar-top-links li.open > a i {
            color: #fff;
        }

    .topbar-main .navbar-top-links li > a {
        padding: 15px 20px;
        height: 100%;
        color: #fff;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

        .topbar-main .navbar-top-links li > a:hover, .topbar-main .navbar-top-links li > a:focus {
            background: #1b4283 !important;
        }

        .topbar-main .navbar-top-links li > a i {
            font-size: 16px;
            color: #fff;
        }

        .topbar-main .navbar-top-links li > a span.badge {
            position: absolute;
            top: 5px;
            right: 30px;
            padding: 3px 6px;
            color: #fff;
        }

        .topbar-main .navbar-top-links li > a img {
            position: relative;
            width: 25px;
            height: 25px;
            display: inline-block;
        }

    .topbar-main .navbar-top-links li .dropdown-menu li {
        display: block;
    }

        .topbar-main .navbar-top-links li .dropdown-menu li:last-child {
            margin-right: 0;
        }

        .topbar-main .navbar-top-links li .dropdown-menu li a {
            padding: 3px 20px;
            min-height: 0;
            color: #ccc;
        }

            .topbar-main .navbar-top-links li .dropdown-menu li a div {
                white-space: normal;
            }

            .topbar-main .navbar-top-links li .dropdown-menu li a.btn {
                color: #fff;
            }

    .topbar-main .navbar-top-links li.topbar-user a {
        padding: 15px 15px 15px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: Open Sans Condensed;
        cursor: pointer
    }

        .topbar-main .navbar-top-links li.topbar-user a img {
            margin-right: 5px;
        }

.topbar-main .navbar-top-links ul.dropdown-alerts {
    width: 250px;
    min-width: 0;
}

    .topbar-main .navbar-top-links ul.dropdown-alerts li {
        float: left;
        width: 100%;
    }

        .topbar-main .navbar-top-links ul.dropdown-alerts li a:hover, .topbar-main .navbar-top-links ul.dropdown-alerts li a:focus {
            background: #f7f7f8 !important;
        }

        .topbar-main .navbar-top-links ul.dropdown-alerts li ul {
            padding: 0;
            margin: 0;
        }

            .topbar-main .navbar-top-links ul.dropdown-alerts li ul li a {
                padding: 15px;
                display: block;
                border-top: 1px solid #efefef;
                font-size: 12px;
                background: #fff;
                color: #333;
            }

                .topbar-main .navbar-top-links ul.dropdown-alerts li ul li a span {
                    margin-right: 10px;
                    padding: 3px;
                }

                    .topbar-main .navbar-top-links ul.dropdown-alerts li ul li a span i {
                        font-size: 14px;
                        color: #fff;
                    }

        .topbar-main .navbar-top-links ul.dropdown-alerts li:first-child a {
            border-top: 0;
        }

        .topbar-main .navbar-top-links ul.dropdown-alerts li.last a {
            width: 100%;
            border-top: 0;
            padding: 10px 15px;
            text-align: right;
            font-size: 12px;
            color: #f46f34;
        }

            .topbar-main .navbar-top-links ul.dropdown-alerts li.last a:hover {
                background: #555 !important;
            }

        .topbar-main .navbar-top-links ul.dropdown-alerts li p {
            padding: 10px 15px;
            margin-bottom: 0;
            background: #1b4283;
            color: #fff;
        }

.topbar-main .navbar-top-links ul.dropdown-messages {
    width: 250px;
    min-width: 0;
}

    .topbar-main .navbar-top-links ul.dropdown-messages li {
        float: left;
        width: 100%;
    }

        .topbar-main .navbar-top-links ul.dropdown-messages li a:hover, .topbar-main .navbar-top-links ul.dropdown-messages li a:focus {
            background: #f7f7f8 !important;
        }

        .topbar-main .navbar-top-links ul.dropdown-messages li ul {
            padding: 0;
            margin: 0;
        }

            .topbar-main .navbar-top-links ul.dropdown-messages li ul li a {
                padding: 15px;
                display: block;
                border-top: 1px solid #efefef;
            }

                .topbar-main .navbar-top-links ul.dropdown-messages li ul li a .avatar img {
                    width: 40px;
                    height: 40px;
                    margin-top: 0;
                    float: left;
                    display: block;
                }

                .topbar-main .navbar-top-links ul.dropdown-messages li ul li a .info {
                    margin-left: 50px;
                    display: block;
                }

                    .topbar-main .navbar-top-links ul.dropdown-messages li ul li a .info .name {
                        font-size: 12px;
                        font-weight: bold;
                        display: block;
                    }

                        .topbar-main .navbar-top-links ul.dropdown-messages li ul li a .info .name .label {
                            font-size: 10px;
                            padding: 3px;
                        }

                    .topbar-main .navbar-top-links ul.dropdown-messages li ul li a .info .desc {
                        font-size: 12px;
                    }

        .topbar-main .navbar-top-links ul.dropdown-messages li:first-child a {
            border-top: 0;
        }

        .topbar-main .navbar-top-links ul.dropdown-messages li.last a {
            background: #f7f7f8;
            width: 100%;
            border-top: 0;
            padding: 10px 15px;
            text-align: right;
            font-size: 12px;
        }

        .topbar-main .navbar-top-links ul.dropdown-messages li p {
            padding: 10px 15px;
            margin-bottom: 0;
            background: #1b4283;
            color: #fff;
        }

.topbar-main .navbar-top-links ul.dropdown-tasks {
    width: 250px;
    min-width: 0;
    margin-left: -59px;
}

    .topbar-main .navbar-top-links ul.dropdown-tasks li {
        float: left;
        width: 100%;
    }

        .topbar-main .navbar-top-links ul.dropdown-tasks li a:hover, .topbar-main .navbar-top-links ul.dropdown-tasks li a:focus {
            background: #f7f7f8 !important;
        }

        .topbar-main .navbar-top-links ul.dropdown-tasks li ul {
            padding: 0;
            margin: 0;
        }

            .topbar-main .navbar-top-links ul.dropdown-tasks li ul li a {
                padding: 15px;
                display: block;
                border-top: 1px solid #efefef;
                font-size: 12px;
            }

                .topbar-main .navbar-top-links ul.dropdown-tasks li ul li a span {
                    margin-right: 10px;
                }

                    .topbar-main .navbar-top-links ul.dropdown-tasks li ul li a span i {
                        font-size: 13px;
                        color: #fff;
                        padding: 3px;
                    }

                .topbar-main .navbar-top-links ul.dropdown-tasks li ul li a .progress {
                    margin-bottom: 5px;
                }

        .topbar-main .navbar-top-links ul.dropdown-tasks li:first-child a {
            border-top: 0;
        }

        .topbar-main .navbar-top-links ul.dropdown-tasks li.last a {
            background: #f7f7f8;
            width: 100%;
            border-top: 0;
            padding: 10px 15px;
            text-align: right;
        }

        .topbar-main .navbar-top-links ul.dropdown-tasks li p {
            padding: 10px 15px;
            margin-bottom: 0;
            background: #1b4283;
            color: #fff;
        }

.topbar-main .navbar-top-links .dropdown-user li a {
    padding: 10px !important;
    height: auto;
}

    .topbar-main .navbar-top-links .dropdown-user li a:hover, .topbar-main .navbar-top-links .dropdown-user li a:focus {
        background: #555 !important;
        color: #f46f34;
    }

    .topbar-main .navbar-top-links .dropdown-user li a i {
        margin-right: 5px;
        color: #999;
    }

    .topbar-main .navbar-top-links .dropdown-user li a:hover i, .topbar-main .navbar-top-links .dropdown-user li a:focus i {
        color: #f46f34;
    }

    .topbar-main .navbar-top-links .dropdown-user li a .badge {
        position: absolute;
        margin-top: 5px;
        right: 10px;
        display: inline;
        font-size: 11px;
        padding: 3px 6px 3px 6px;
        text-align: center;
        vertical-align: middle;
    }

@media (min-width:768px) {
    .navbar-top-links .dropdown-messages, .navbar-top-links .dropdown-tasks, .navbar-top-links .dropdown-alerts {
        margin-left: auto;
    }

    #customerInformation {
      display: block
    }

    .notificationsubjectwrap {
      display: block
    }
}

.page-title-breadcrumb {
    padding: 10px 20px;
    background: #fff;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
    clear: both;
}

    .page-title-breadcrumb .page-header {
        margin: 0;
        padding: 0;
        border-bottom: 0;
    }

        .page-title-breadcrumb .page-header .page-title {
            font-size: 25px;
            font-weight: 300;
            /*  display:inline-block;*/
        }

    .page-title-breadcrumb .page-title .btn {
        vertical-align: middle;
        margin: 5px 0;
    }

    .page-title-breadcrumb .page-header .page-subtitle {
        font-size: 14px;
        font-weight: 300;
        color: #bcbcbc;
        display: inline-block;
    }

    .page-title-breadcrumb .breadcrumb {
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
        background: transparent;
    }

        .page-title-breadcrumb .breadcrumb li + li:before {
            content: "";
            padding: 0;
        }

.page-title-text,
.page-title-group {
    display: flex;
}

.page-title-group {
  width: 100%;
}

    .page-title-text.limited {
        max-width: calc(100% - 250px);
    }

.page-title-group {
    margin-left: 20px;
}

.horizontal-menu-page #sidebar {
    display: none;
}

.horizontal-menu-page #page-wrapper {
    margin-left: 0;
}

.horizontal-menu-page #menu-toggle {
    display: none;
}

#chat-form {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 280px;
    background: #1b4283;
    z-index: 9999;
    display: none;
}

    #chat-form.fixed {
        position: fixed;
        min-height: 0 !important;
    }

    #chat-form .user-status {
        display: inline-block;
        background: #575d67;
        margin-right: 5px;
        width: 8px;
        height: 8px;
        -webkit-background-clip: padding-box;
        -moz-background-clip: padding;
        background-clip: padding-box;
        -webkit-border-radius: 8 !important;
        -moz-border-radius: 8px !important;
        border-radius: 8px !important;
    }

        #chat-form .user-status.is-online {
            background-color: #06b53c;
        }

        #chat-form .user-status.is-busy {
            background-color: #ee4749;
        }

        #chat-form .user-status.is-idle {
            background-color: #f7d227;
        }

        #chat-form .user-status.is-offline {
            background-color: #666666;
        }

    #chat-form .chat-inner {
        overflow: auto;
        height: 100%;
    }

    #chat-form .chat-header {
        font-size: 16px;
        color: #fff;
        padding: 30px 35px;
        line-height: 1;
        margin: 0;
        border-bottom: 1px solid #607885;
        position: relative;
    }

        #chat-form .chat-header .chat-form-close {
            color: #ededed;
            font-size: 13px;
        }

    #chat-form .chat-group {
        margin-top: 30px;
    }

        #chat-form .chat-group > strong {
            text-transform: uppercase;
            color: #ededed;
            display: block;
            padding: 6px 35px;
            font-size: 14px;
        }

        #chat-form .chat-group > a {
            display: block;
            padding: 6px 35px;
            position: relative;
            color: #eaeaea;
            text-decoration: none;
        }

            #chat-form .chat-group > a .badge {
                font-size: 9px;
                margin-left: 5px;
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -o-transform: scale(1);
                -ms-transform: scale(1);
                transform: scale(1);
                -webkit-opacity: 1;
                -moz-opacity: 1;
                opacity: 1;
            }

                #chat-form .chat-group > a .badge.is-hidden {
                    -webkit-opacity: 0;
                    -moz-opacity: 0;
                    opacity: 0;
                }

            #chat-form .chat-group > a.active {
                background: #566a76;
            }

                #chat-form .chat-group > a.active:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 0px;
                    height: 0px;
                    border-style: solid;
                    border-width: 8px 0 8px 8px;
                    border-color: transparent transparent transparent #405058;
                    left: 0;
                    top: 50%;
                    margin-top: -8px;
                }

            #chat-form .chat-group > a:hover {
                background: #566a76;
            }

    #chat-form #chat-box {
        position: absolute;
        right: 280px;
        width: 340px;
        background: #405058;
        -webkit-background-clip: padding-box;
        -moz-background-clip: padding;
        background-clip: padding-box;
        display: none;
    }

        #chat-form #chat-box .chat-box-header {
            padding: 20px 24px;
            font-size: 14px;
            color: #fff;
            border-bottom: 1px solid #607885;
        }

            #chat-form #chat-box .chat-box-header .chat-box-close {
                color: #ededed;
                font-size: 13px;
            }

            #chat-form #chat-box .chat-box-header small {
                color: #BBBBBB;
                font-size: 12px;
                padding-left: 8px;
            }

        #chat-form #chat-box ul.chat-box-body {
            list-style: none;
            margin: 0;
            padding: 0;
            overflow: auto;
            height: 250px;
        }

            #chat-form #chat-box ul.chat-box-body > li {
                padding: 20px 24px;
                padding-bottom: 5px;
                padding-top: 0px;
            }

                #chat-form #chat-box ul.chat-box-body > li:before {
                    content: " ";
                    display: table;
                }

                #chat-form #chat-box ul.chat-box-body > li:after {
                    clear: both;
                    content: " ";
                    display: table;
                }

                #chat-form #chat-box ul.chat-box-body > li.odd {
                    background: #45555e;
                }

                #chat-form #chat-box ul.chat-box-body > li .avt {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin: 5px 5px 0px 0px;
                    vertical-align: -9px;
                }

                #chat-form #chat-box ul.chat-box-body > li .user {
                    font-weight: bold;
                    color: #fff;
                }

                    #chat-form #chat-box ul.chat-box-body > li .user:after {
                        content: ':';
                    }

                #chat-form #chat-box ul.chat-box-body > li .time {
                    float: right;
                    font-style: italic;
                    color: #ededed;
                    font-size: 11px;
                    margin-top: 12px;
                }

                #chat-form #chat-box ul.chat-box-body > li p {
                    margin: 10px 0 8.5px;
                    color: #eaeaea;
                }

        #chat-form #chat-box .chat-textarea {
            padding: 20px 24px;
            position: relative;
        }

            #chat-form #chat-box .chat-textarea textarea {
                background: #516570;
                border: 1px solid #516570;
                color: #fff;
                border-radius: 0;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
            }

                #chat-form #chat-box .chat-textarea textarea::-webkit-input-placeholder { /* WebKit browsers */
                    color: #ededed;
                }

                #chat-form #chat-box .chat-textarea textarea:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                    color: #ededed;
                }

                #chat-form #chat-box .chat-textarea textarea::-moz-placeholder { /* Mozilla Firefox 19+ */
                    color: #ededed;
                }

                #chat-form #chat-box .chat-textarea textarea:-ms-input-placeholder { /* Internet Explorer 10+ */
                    color: #ededed;
                }

.fixed-topbar #topbar {
    position: fixed;
}

.fixed-topbar #sidebar {
    padding-top: 50px !important;
}

.fixed-topbar #page-wrapper {
    padding-top: 50px !important;
}

#sidebar {
    background: #ededed;
}

.navbar-static-side {
    -webkit-transition: 0.3s all linear;
    -moz-transition: 0.3s all linear;
    -ms-transition: 0.3s all linear;
    -o-transition: 0.3s all linear;
    transition: 0.3s all linear;
}

    .navbar-static-side ul li {
        /* border-bottom: 1px dotted rgb(31, 31, 13); */
        border-radius: 0px !important;
        margin-left: 1%;
        margin-right: 0.5%;
    }

        .navbar-static-side ul li.active a, .navbar-static-side ul li.active a:hover, .navbar-static-side ul li.active a:focus {
            background: #f46f34;
            outline: none;
            color: rgba(255, 255, 255, 1);
        }

        .st1,
        .navbar-static-side ul li a {
            width:100%;
            color: rgba(0, 0, 0, 0.658823);
            padding: 15px;
            font-weight: 700;
            text-transform: uppercase;
            font-family: Open Sans Condensed;
            border-radius: 9px;
        }

            .navbar-static-side ul li a span.menu-title {
                margin-left: 10px;
            }

            .navbar-static-side ul li a i {
                font-size: 16px;
                -webkit-transition: font-size 0.3s ease-out;
                -moz-transition: font-size 0.3s ease-out;
                -ms-transition: font-size 0.3s ease-out;
                -o-transition: font-size 0.3s ease-out;
                transition: font-size 0.3s ease-out;
            }

            .navbar-static-side ul li a:focus,
            .navbar-static-side ul li a.selected {
                border-radius: 9px;
                background: #0074bd;
                transition: 0.2s all ease-in-out;
                outline: none;
                color: white;
            }
            .navbar-static-side ul li a.selected:hover {
                color: white !important;
                background: #0074bd;
            }
            .navbar-static-side ul li a:hover{
                transition: 0.2s all ease-in-out;
                outline: none;
                color: #0074bd;
            }

            div.sidebar-icons a.selected {
                background: none !important;
                color: #0074bd !important;
            }
            /*.navbar-static-side ul li a:hover i,
.navbar-static-side ul li a:focus i, .navbar-static-side ul li.active a i{color:#f46f34;}*/
            .navbar-static-side ul li a .badge, .navbar-static-side ul li a .label {
                margin-top: -11px;
                margin-left: -3px;
                padding: 5px;
                padding-top: 1px;
            }

            .navbar-static-side ul li a.menu-title {
                margin-left: 10px;
            }

        .navbar-static-side ul li .nav-second-level li {
            border-top: 1px dotted rgba(255, 255, 255, 0.2);
            border-bottom: 0;
        }

.navbar-static-side ul li .nav-second-level li.active a,
.navbar-static-side ul li .nav-second-level li:hover a,
.navbar-static-side ul li .nav-second-level li:focus a {
    color: #fff;
}


.navbar-static-side ul li .nav-second-level li a {
    padding: 15px 15px 15px 25px;
    background: #405058;
    color: #efefef;
}

.list-group-item-action li a {
    padding: 15px 15px 15px 25px;
    background: #405058;
    color: #efefef;
}

.navbar-static-side ul li .nav-second-level li.active a,
.navbar-static-side ul li .nav-second-level li.active a:hover,
.navbar-static-side ul li .nav-second-level li.active a:focus {
    background: #f46f34;
    outline: none;
    color: rgba(255, 255, 255, 1);
}

span.submenu-title,
.navbar-static-side ul li .nav-second-level li a span.submenu-title {
    margin-left: 10px;
}

.navbar-static-side ul li .nav-second-level li .nav-third-level li {
    border-bottom: 1px solid #42525b;
}

    .navbar-static-side ul li .nav-second-level li .nav-third-level li.active a, .navbar-static-side ul li .nav-second-level li .nav-third-level li:hover a, .navbar-static-side ul li .nav-second-level li .nav-third-level li:focus a {
        color: #fff;
    }

    .navbar-static-side ul li .nav-second-level li .nav-third-level li a {
        padding: 15px 15px 15px 25px;
        background: #3a484f;
        color: #efefef;
    }

        .navbar-static-side ul li .nav-second-level li .nav-third-level li a span.submenu-title {
            margin-left: 0;
        }

.navbar-static-side ul li.sidebar-heading {
    padding: 5px 15px;
}

    .navbar-static-side ul li.sidebar-heading h4 {
        font-size: 18px;
        font-weight: bold;
        color: #0a819c;
    }

.navbar-static-side ul li.user-panel {
    height: 67px;
    padding: 10px;
}

    .navbar-static-side ul li.user-panel .thumb {
        float: left;
        border: 5px solid rgba(255, 255, 255, 0.1);
        border-radius: 50%;
    }

        .navbar-static-side ul li.user-panel .thumb img {
            width: 45px;
            height: 45px;
        }

    .navbar-static-side ul li.user-panel .info {
        float: left;
        padding: 5px;
        color: #000;
    }

        .navbar-static-side ul li.user-panel .info p {
            margin-bottom: 3px;
            font-size: 16px;
            font-family: Open Sans Condensed;
            text-transform: uppercase;
        }

        .navbar-static-side ul li.user-panel .info a {
            font-size: 10px;
        }

            .navbar-static-side ul li.user-panel .info a i {
                font-size: 14px;
            }

            .navbar-static-side ul li.user-panel .info a:hover, .navbar-static-side ul li.user-panel .info a:focus {
                background-color: transparent;
            }

                .navbar-static-side ul li.user-panel .info a:hover i, .navbar-static-side ul li.user-panel .info a:focus i {
                    color: #0074bd;
                }

    .navbar-static-side ul li.user-panel ul li {
        border-bottom: 0;
    }

.sidebar-user-info {
    padding: 15px;
}

    .sidebar-user-info img {
        border: 5px solid #09758e;
        width: 75px;
        height: 75px;
        display: inline-block;
    }

    .sidebar-user-info h4 {
        color: #fff;
        margin-bottom: 5px;
    }

    .sidebar-user-info ul {
        margin-bottom: 3px;
    }

        .sidebar-user-info ul li {
            border-bottom: 0 !important;
        }

            .sidebar-user-info ul li a {
                color: #cdcdcd;
            }

    .sidebar-user-info .user-status {
        width: 10px;
        height: 10px;
        border-radius: 50% !important;
        display: inline-block;
        background: transparent;
        margin-right: 5px;
    }

        .sidebar-user-info .user-status.is-online {
            background: #06B53C;
        }

        .sidebar-user-info .user-status.is-idle {
            background: #F7D227;
        }

        .sidebar-user-info .user-status.is-busy {
            background: #ee4749;
        }

        .sidebar-user-info .user-status.is-offline {
            background: #666666;
        }

    .sidebar-user-info span {
        color: #fff;
    }

.sidebar-user-activity {
    padding: 15px;
    border-bottom: 0 !important;
}

    .sidebar-user-activity h4 {
        color: #fff;
    }

    .sidebar-user-activity .note {
        padding: 3px 15px;
        margin-bottom: 5px;
    }

        .sidebar-user-activity .note small {
            color: #cdcdcd;
        }

        .sidebar-user-activity .note a:hover, .sidebar-user-activity .note a:focus {
            background: transparent;
        }

.residentdot .arrow {
    float: right;
    margin-top: 3px;
    left: -26px !important;
    margin-top: -12px !important;
    border-right-color: #000000 !important;

}

.fa.arrow:before {
    content: "\f104";
}

.active > a > .fa.arrow:before {
    content: "\f107";
}

.right-sidebar #topbar .navbar-header {
    float: right;
    border-right: 0;
}

.right-sidebar #sidebar {
    left: auto;
    right: 0;
}

.right-sidebar #page-wrapper {
    margin: 0 250px 0 0;
}


/* End Left Sidebar Collapsed */

/* Begin Right Sidebar Collapsed */
.right-side-collapsed .navbar-static-side {
    width: 55px;
}

    .right-side-collapsed .navbar-static-side ul.sidebar-navigation-menu li.user-panel {
        display: none;
    }

    .right-side-collapsed .navbar-static-side ul.sidebar-navigation-menu li.nav-hover a {
        height: 50px;
    }

        .right-side-collapsed .navbar-static-side ul.sidebar-navigation-menu li.nav-hover a span.menu-title {
            display: block !important;
        }

        .right-side-collapsed .navbar-static-side ul.sidebar-navigation-menu li.nav-hover a span.submenu-title {
            display: block !important;
            margin-right: 0;
        }

    .right-side-collapsed .navbar-static-side ul.sidebar-navigation-menu li.nav-hover ul.nav-second-level {
        display: block;
        position: absolute;
        top: 50px;
        right: 55px;
        width: 195px;
    }

        .right-side-collapsed .navbar-static-side ul.sidebar-navigation-menu li.nav-hover ul.nav-second-level li a {
            padding: 15px 5px;
        }

    .right-side-collapsed .navbar-static-side ul.sidebar-navigation-menu li a span {
        display: none;
    }

    .right-side-collapsed .navbar-static-side ul.sidebar-navigation-menu li a i.fa {
        font-size: 18px;
    }

    .right-side-collapsed .navbar-static-side ul.sidebar-navigation-menu li a span.menu-title {
        position: absolute;
        top: 0;
        right: 55px;
        padding: 15px;
        margin-right: 0;
        background: #0a819c;
        color: #fff;
        width: 195px;
        height: 50px;
    }

    .right-side-collapsed .navbar-static-side ul.sidebar-navigation-menu li ul.nav-second-level {
        display: none;
        position: absolute;
        top: 50px;
        right: 55px;
        width: 195px;
    }

        .right-side-collapsed .navbar-static-side ul.sidebar-navigation-menu li ul.nav-second-level li a i {
            display: none;
        }

.right-side-collapsed #page-wrapper {
    margin: 0 55px 0 0;
}

/* End Left Sidebar Collapsed */
.page-content {
    padding: 20px 20px 43px 20px;
}

.alt-background-color{
  background-color: #f0f2f5;
}
/* DEP?? */
#footer {
    position: fixed;
    height: 64px;
    left: 0;
    width: 100%;
    bottom: 0px;
    padding: 5px;
    background: #fff;
    color: #1b4283;
    z-index: 99;
    box-shadow: 0 0 5px #ccc;
}

    #footer a {
        color: #1b4283;
    }

        #footer a:hover {
            color: #1b4283;
            text-decoration: underline;
        }

    #footer img {
        margin-right: 10px;
    }

/* DEP END*/



/* Sidebar Footer */

.sidebar-collapse .footer {
    padding: 40px 20px 20px;
    color: rgba(0, 0, 0, 0.658823);
    width: 100%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1f1f1f+0,1f1f1f+100&0+0,1+35 */
    /*background: -moz-linear-gradient(top, rgba(31,31,31,0) 0%, rgba(31,31,31,1) 35%, rgba(31,31,31,1) 100%); /* FF3.6-15 */
    /*background: -webkit-linear-gradient(top, rgba(31,31,31,0) 0%,rgba(31,31,31,1) 35%,rgba(31,31,31,1) 100%); /* Chrome10-25,Safari5.1-6 */
    /*background: linear-gradient(to bottom, rgba(31,31,31,0) 0%,rgba(31,31,31,1) 35%,rgba(31,31,31,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001f1f1f', endColorstr='#1f1f1f',GradientType=0 ); /* IE6-9 */
}


/* Sidebar Footer END */


.f-content {
    font-size: 11px;
    padding: 4px 0 3px;
}

.navbar-default .navbar-toggle {
    z-index: 99;
}

#theme-setting {
    position: fixed;
    right: -250px;
    top: 120px;
    padding: 10px 13px;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-right: 0;
    width: 250px;
    z-index: 9999;
    -webkit-transition: 0.4s all ease-in-out;
    transition: 0.4s all ease-in-out;
}

    #theme-setting .btn-theme-setting {
        padding: 15px;
        background: #fff;
        position: absolute;
        z-index: 9999;
        left: -57px;
        border: 1px solid;
        border-color: #e5e5e5 transparent #e5e5e5 #e5e5e5;
        top: -1px;
    }

        #theme-setting .btn-theme-setting i {
            font-size: 30px;
            color: #0a819c;
            line-height: 25px;
        }

        #theme-setting .btn-theme-setting:hover, #theme-setting .btn-theme-setting:focus {
            color: #999;
            outline: none;
        }

    #theme-setting .content-theme-setting ul#list-color li {
        cursor: pointer;
        width: 35px;
        height: 35px;
        border: 5px solid transparent;
        margin: 0 5px;
    }

        #theme-setting .content-theme-setting ul#list-color li.green-dark {
            background: #594857;
            border-color: #488c6c;
        }

        #theme-setting .content-theme-setting ul#list-color li.red-dark {
            background: #594857;
            border-color: #bf4346;
        }

        #theme-setting .content-theme-setting ul#list-color li.pink-dark {
            background: #594857;
            border-color: #bf3773;
        }

        #theme-setting .content-theme-setting ul#list-color li.blue-dark {
            background: #594857;
            border-color: #0a819c;
        }

        #theme-setting .content-theme-setting ul#list-color li.yellow-dark {
            background: #594857;
            border-color: #f2994b;
        }

        #theme-setting .content-theme-setting ul#list-color li.green-grey {
            background: #1b4283;
            border-color: #488c6c;
        }

        #theme-setting .content-theme-setting ul#list-color li.red-grey {
            background: #1b4283;
            border-color: #bf4346;
        }

        #theme-setting .content-theme-setting ul#list-color li.pink-grey {
            background: #1b4283;
            border-color: #bf3773;
        }

        #theme-setting .content-theme-setting ul#list-color li.blue-grey {
            background: #1b4283;
            border-color: #0a819c;
        }

        #theme-setting .content-theme-setting ul#list-color li.yellow-grey {
            background: #1b4283;
            border-color: #f2994b;
        }

        #theme-setting .content-theme-setting ul#list-color li.yellow-green {
            background: #007451;
            border-color: #CCA32F;
        }

        #theme-setting .content-theme-setting ul#list-color li.orange-grey {
            background: #322F2B;
            border-color: #D94E37;
        }

        #theme-setting .content-theme-setting ul#list-color li.pink-blue {
            background: #40516F;
            border-color: #DC6767;
        }

        #theme-setting .content-theme-setting ul#list-color li.pink-violet {
            background: #554161;
            border-color: #E82A62;
        }

        #theme-setting .content-theme-setting ul#list-color li.orange-violet {
            background: #554161;
            border-color: #FF422B;
        }

        #theme-setting .content-theme-setting ul#list-color li.pink-green {
            background: #456445;
            border-color: #FF5E70;
        }

        #theme-setting .content-theme-setting ul#list-color li.pink-brown {
            background: #573F2F;
            border-color: #A21E46;
        }

        #theme-setting .content-theme-setting ul#list-color li.orange-blue {
            background: #33485C;
            border-color: #E74C3C;
        }

        #theme-setting .content-theme-setting ul#list-color li.yellow-blue {
            background: #417CB2;
            border-color: #FFC34C;
        }

        #theme-setting .content-theme-setting ul#list-color li.green-blue {
            background: #417CB2;
            border-color: #66B354;
        }

/*panel stat*/
#sum_box .db {
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

#sum_box .icon {
    color: #999;
    font-size: 55px;
    margin-top: 7px;
    margin-bottom: 0px;
    float: right;
}

#sum_box h4 {
    text-align: left;
    margin-top: 0px;
    font-size: 30px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

    #sum_box h4 span:last-child {
        font-size: 25px;
    }

#sum_box p.description {
    margin-top: 0px;
    opacity: .6;
}

#sum_box .db:hover {
    background: #1b4283;
    color: #fff;
}

    #sum_box .db:hover .icon {
        opacity: 1;
        color: #fff;
    }

    #sum_box .db:hover p.description {
        opacity: 1;
    }

/*panel stat*/

/*user profile*/
.profile {
    display: inline-block;
}

    .profile h2 {
        margin-top: 0;
    }

    .profile .divider {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

figcaption.ratings {
    margin-top: 20px;
}

    figcaption.ratings a {
        color: #f1c40f;
        font-size: 11px;
    }

.emphasis {
    border-top: 4px solid transparent;
    padding-top: 15px;
}

    .emphasis:hover {
        border-top: 4px solid #1abc9c;
    }

    .emphasis h2 {
        margin-bottom: 0;
    }

/*user profile*/

/*to-do list*/
ul.todo-list {
    overflow: hidden;
    width: auto;
    height: 250px;
    padding: 0;
}

    ul.todo-list li {
        background: #f3f3f3;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        position: relative;
        padding: 13px;
        margin-bottom: 5px;
        cursor: move;
        list-style: none;
    }

        ul.todo-list li span.drag-drop {
            height: 17px;
            display: block;
            float: left;
            width: 7px;
            position: relative;
            top: 2px;
        }

            ul.todo-list li span.drag-drop i {
                height: 2px;
                width: 2px;
                display: block;
                background: #ccc;
                box-shadow: 5px 0 0 0px #ccc, 0px 5px 0 0px #ccc, 5px 5px 0 0px #ccc, 0px 10px 0 0px #ccc, 5px 10px 0 0px #ccc, 0px 15px 0 0px #ccc, 5px 15px 0 0px #ccc;
                -webkit-box-shadow: 5px 0 0 0px #ccc, 0px 5px 0 0px #ccc, 5px 5px 0 0px #ccc, 0px 10px 0 0px #ccc, 5px 10px 0 0px #ccc, 0px 15px 0 0px #ccc, 5px 15px 0 0px #ccc;
                -moz-box-shadow: 5px 0 0 0px #ccc, 0px 5px 0 0px #ccc, 5px 5px 0 0px #ccc, 0px 10px 0 0px #ccc, 5px 10px 0 0px #ccc, 0px 15px 0 0px #ccc, 5px 15px 0 0px #ccc;
            }

        ul.todo-list li .todo-check {
            margin-left: 10px;
            margin-right: 10px;
        }

        ul.todo-list li .todo-title {
            margin-right: 70px;
        }

        ul.todo-list li .todo-actions {
            position: absolute;
            right: 15px;
            top: 13px;
        }

            ul.todo-list li .todo-actions a i {
                color: #999;
                margin: 0 5px;
            }

            ul.todo-list li .todo-actions a:hover i, ul.todo-list li .todo-actions a:focus i {
                color: #555555;
            }

/*to-do list*/

/*chat form*/
ul.chats {
    margin: 0;
    padding: 0;
}

    ul.chats li {
        list-style: none;
        margin: 30px auto;
        font-size: 12px;
    }

        ul.chats li:first-child {
            margin-top: 0;
        }

        ul.chats li img.avatar {
            width: 50px;
            height: 50px;
            -webkit-border-radius: 50% !important;
            -moz-border-radius: 50% !important;
            border-radius: 50% !important;
        }

        ul.chats li .message {
            display: block;
            padding: 7px;
            position: relative;
        }

            ul.chats li .message .chat-datetime {
                font-style: italic;
                color: #888;
                font-size: 11px;
            }

            ul.chats li .message .chat-body {
                display: block;
                margin-top: 5px;
            }

        ul.chats li.in img.avatar {
            float: left;
        }

        ul.chats li.in .message {
            background: #fafbfc;
            margin-left: 65px;
            border-left: 3px solid #e5e5e5;
            border-radius: 4px;
        }

.chats li.in .message .chat-arrow {
    display: block;
    position: absolute;
    top: 15px;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #e5e5e5;
}

ul.chats li.in .message a.chat-name {
    color: #0a819c;
}

ul.chats li.out img.avatar {
    float: right;
}

ul.chats li.out .message {
    background: #f3f3f3;
    margin-right: 65px;
    border-right: 3px solid #e5e5e5;
    text-align: right;
    border-radius: 4px;
}

    ul.chats li.out .message .chat-arrow {
        display: block;
        position: absolute;
        top: 15px;
        right: -10px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #e5e5e5;
    }

    ul.chats li.out .message a.chat-name {
        color: #0a819c;
    }

    ul.chats li.out .message a.chat-name, ul.chats li.out .message a.chat-datetime {
        text-align: right;
    }

.chat-form {
    margin-top: 15px;
    padding: 10px;
    background-color: #f0f0f0;
    overflow: hidden;
    clear: both;
}

    .chat-form #input-chat, .chat-form .input-group-btn .btn {
        border: 0;
    }

    .chat-form .input-group-btn:last-child > .btn, .chat-form .input-group-btn:last-child > .btn-group {
        margin-left: 0;
    }

/*chat form*/
.row-icons {
    padding: 15px 0;
    margin: 0;
}

    .row-icons [class*="col"] {
        margin-bottom: 20px;
        padding: 0;
    }

        .row-icons [class*="col"] a {
            line-height: 30px;
            display: inline-block;
            color: #999;
            text-decoration: none;
        }

            .row-icons [class*="col"] a:hover i.fa:before {
                color: #0da9cc;
            }

            .row-icons [class*="col"] a:hover i.glyphicon:before {
                color: #0da9cc;
            }

        .row-icons [class*="col"] i.fa {
            vertical-align: middle;
            margin: 0 10px;
            width: 40px;
        }

        .row-icons [class*="col"] i:before {
            color: #0a819c;
            font-size: 30px;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }

        .row-icons [class*="col"] span {
            padding-left: 20px;
            vertical-align: top;
        }

.modal-full-width {
    width: 100%;
}

.modal-wide-width {
    width: 70%;
}

#error-page {
    background: #F0F2F5;
    text-align: center;
    position: relative;
}

    #error-page #error-page-content {
        width: 480px;
        margin: 10% auto 0 auto;
        text-align: center;
    }

        #error-page #error-page-content h1 {
            font-size: 150px;
            font-weight: bold;
            color: #0a819c;
        }

        #error-page #error-page-content p a {
            color: #0a819c;
        }

            #error-page #error-page-content p a:hover, #error-page #error-page-content p a:focus {
                text-decoration: underline;
            }

#signin-page {
    background: #fff;
    height: auto;
}

#signup-page {
}

#lock-screen-page {
}

    #lock-screen-page .page-form {
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff;
        box-shadow: 0px 2px 10px #ccc;
        -webkit-box-shadow: 0px 2px 10px #ccc;
        -moz-box-shadow: 0px 2px 10px #ccc;
    }

.page-form {
    max-width: 450px;
    margin: 5% auto 0 auto;
    background: none;
    border-radius: 4px;
}

    .page-form .input-icon i {
        margin-top: 12px;
    }

    .page-form input[type='text'], .page-form input[type='password'], .page-form input[type='email'], .page-form select {
        height: 40px;
        border-color: #e5e5e5;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        color: #999;
    }

    .page-form .header-content {
        padding: 10px 0;
        margin-bottom: 20px;
    }

        .page-form .header-content h1 {
            font-size: 30px;
            margin: 0;
            text-transform: uppercase;
            color: #428bca;
        }

    .page-form .body-content {
        padding: 15px 20px;
        position: relative;
        background: #f6f6f6;
        margin: 30px 0 0;
        border-top: 10px solid #428bca;
    }

        .page-form .body-content .btn-twitter {
            background: #5bc0de;
            margin-bottom: 10px;
            color: #fff;
        }

            .page-form .body-content .btn-twitter i {
                margin-right: 5px;
            }

            .page-form .body-content .btn-twitter:hover, .page-form .body-content .btn-twitter:focus {
                color: #fff;
            }

        .page-form .body-content .btn-facebook {
            background: #418bca;
            margin-bottom: 10px;
            color: #fff;
        }

            .page-form .body-content .btn-facebook i {
                margin-right: 5px;
            }

            .page-form .body-content .btn-facebook:hover, .page-form .body-content .btn-facebook:focus {
                color: #fff;
            }

        .page-form .body-content .btn-google-plus {
            background: #dd4c39;
            margin-bottom: 10px;
            color: #fff;
        }

            .page-form .body-content .btn-google-plus i {
                margin-right: 5px;
            }

            .page-form .body-content .btn-google-plus:hover, .page-form .body-content .btn-google-plus:focus {
                color: #fff;
            }

        .page-form .body-content .forget-password h4 {
            text-transform: uppercase;
            font-size: 16px;
        }

        .page-form .body-content hr {
            border-color: #e0e0e0;
        }

    .page-form .state-error + em {
        display: block;
        margin-top: 6px;
        padding: 0 1px;
        font-style: normal;
        font-size: 11px;
        line-height: 15px;
        color: #d9534f;
    }

    .page-form .rating.state-error + em {
        margin-top: -4px;
        margin-bottom: 4px;
    }

    .page-form .state-success + em {
        display: block;
        margin-top: 6px;
        padding: 0 1px;
        font-style: normal;
        font-size: 11px;
        line-height: 15px;
        color: #d9534f;
    }

    .page-form .state-error input, .page-form .state-error select {
        background: #f2dede;
    }

    .page-form .state-success input, .page-form .state-success select {
        background: #dff0d8;
    }

    .page-form .note-success {
        color: #5cb85c;
    }

    .page-form label {
        font-weight: normal;
        margin-bottom: 0;
    }

.checkbox-list label {
    cursor: pointer;
}

#lock-screen-page .page-form {
    margin-top: 15%;
    text-align: center;
}

    #lock-screen-page .page-form h1 {
        margin: 0;
        color: #428bca;
    }

#lock-screen-info .email {
    color: #428bca;
}

#lock-screen-avatar {
    top: -78px;
    left: 50%;
    margin-left: -74px;
    position: absolute;
    display: inline-block;
}

    #lock-screen-avatar img {
        border: 10px solid #eeeeee;
    }

#lock-screen-info {
    margin-top: 60px;
}

@media only screen and (max-width:480px) {
    .page-form {
        width: 280px;
    }

    #lock-screen-page .page-form {
        margin-top: 35%;
    }
}

canvas {
    width: 100% !important;
    max-width: 800px;
    height: auto !important;
}

.timeline-centered {
    position: relative;
    margin-bottom: 30px;
}

    .timeline-centered.timeline-sm .timeline-entry {
        margin-bottom: 20px !important;
    }

        .timeline-centered.timeline-sm .timeline-entry .timeline-entry-inner .timeline-label {
            padding: 1em;
        }

    .timeline-centered:before, .timeline-centered:after {
        content: " ";
        display: table;
    }

    .timeline-centered:after {
        clear: both;
    }

    .timeline-centered:before {
        content: '';
        position: absolute;
        display: block;
        width: 7px;
        background: #fff;
        left: 50%;
        top: 20px;
        bottom: 20px;
        margin-left: -4px;
    }

    .timeline-centered .timeline-entry {
        position: relative;
        width: 50%;
        float: right;
        margin-bottom: 70px;
        clear: both;
    }

        .timeline-centered .timeline-entry:before, .timeline-centered .timeline-entry:after {
            content: " ";
            display: table;
        }

        .timeline-centered .timeline-entry:after {
            clear: both;
        }

        .timeline-centered .timeline-entry.begin {
            margin-bottom: 0;
        }

        .timeline-centered .timeline-entry.left-aligned {
            float: left;
        }

            .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner {
                margin-left: 0;
                margin-right: -28px;
            }

                .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time {
                    left: auto;
                    right: -115px;
                    text-align: left;
                }

                .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-icon {
                    float: right;
                }

                .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label {
                    margin-left: 0;
                    margin-right: 85px;
                }

                    .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label:after {
                        left: auto;
                        right: 0;
                        margin-left: 0;
                        margin-right: -9px;
                        -moz-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }

        .timeline-centered .timeline-entry .timeline-entry-inner {
            position: relative;
            margin-left: -31px;
        }

            .timeline-centered .timeline-entry .timeline-entry-inner:before, .timeline-centered .timeline-entry .timeline-entry-inner:after {
                content: " ";
                display: table;
            }

            .timeline-centered .timeline-entry .timeline-entry-inner:after {
                clear: both;
            }

            .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time {
                position: absolute;
                left: -115px;
                text-align: right;
                padding: 10px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span {
                    display: block;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span:first-child {
                        font-size: 18px;
                        font-weight: bold;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span:last-child {
                        font-size: 12px;
                    }

            .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon {
                background: #fff;
                color: #999;
                display: block;
                width: 60px;
                height: 60px;
                -webkit-background-clip: padding-box;
                -moz-background-clip: padding-box;
                background-clip: padding-box;
                border-radius: 50%;
                text-align: center;
                border: 7px solid #fff;
                line-height: 45px;
                font-size: 15px;
                float: left;
            }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-primary {
                    background-color: #0a819c;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-success {
                    background-color: #5cb85c;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-info {
                    background-color: #5bc0de;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-warning {
                    background-color: #f0ad4e;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-danger {
                    background-color: #d9534f;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-red {
                    background-color: #bf4346;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-green {
                    background-color: #488c6c;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-blue {
                    background-color: #0a819c;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-yellow {
                    background-color: #f2994b;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-orange {
                    background-color: #e9662c;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-pink {
                    background-color: #bf3773;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-violet {
                    background-color: #9351ad;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-grey {
                    background-color: #1b4283;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-dark {
                    background-color: #594857;
                    color: #fff;
                }

            .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label {
                position: relative;
                background: #fff;
                padding: 1.7em;
                margin-left: 85px;
                -webkit-background-clip: padding-box;
                -moz-background-clip: padding;
                background-clip: padding-box;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                border-radius: 3px;
            }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-red {
                    background: #bf4346;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-red:after {
                        border-color: transparent #bf4346 transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-red .timeline-title, .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-red p {
                        color: #fff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-green {
                    background: #488c6c;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-green:after {
                        border-color: transparent #488c6c transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-green .timeline-title, .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-green p {
                        color: #fff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-orange {
                    background: #e9662c;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-orange:after {
                        border-color: transparent #e9662c transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-orange .timeline-title, .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-orange p {
                        color: #fff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-yellow {
                    background: #f2994b;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-yellow:after {
                        border-color: transparent #f2994b transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-yellow .timeline-title, .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-yellow p {
                        color: #fff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-blue {
                    background: #0a819c;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-blue:after {
                        border-color: transparent #0a819c transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-blue .timeline-title, .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-blue p {
                        color: #fff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-pink {
                    background: #bf3773;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-pink:after {
                        border-color: transparent #bf3773 transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-pink .timeline-title, .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-pink p {
                        color: #fff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-violet {
                    background: #9351ad;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-violet:after {
                        border-color: transparent #9351ad transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-violet .timeline-title, .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-violet p {
                        color: #fff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-grey {
                    background: #1b4283;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-grey:after {
                        border-color: transparent #1b4283 transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-grey .timeline-title, .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-grey p {
                        color: #fff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-dark {
                    background: #594857;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-dark:after {
                        border-color: transparent #594857 transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-dark .timeline-title, .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-dark p {
                        color: #fff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 9px 9px 9px 0;
                    border-color: transparent #fff transparent transparent;
                    left: 0;
                    top: 20px;
                    margin-left: -9px;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title, .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p {
                    color: #999;
                    margin: 0;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p + p {
                        margin-top: 15px;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title {
                    margin-bottom: 10px;
                    font-weight: bold;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title span {
                        -webkit-opacity: .6;
                        -moz-opacity: .6;
                        opacity: .6;
                        -ms-filter: alpha(opacity=60);
                        filter: alpha(opacity=60);
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p .timeline-img {
                    margin: 5px 10px 0 0;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p .timeline-img.pull-right {
                        margin: 5px 0 0 10px;
                    }

.tab-content.tab-edit {
    background: transparent;
    border: 0px;
}

ul.ul-edit.navbar.nav-tabs {
    border-bottom: 5px solid #fff !important;
}

    ul.ul-edit.navbar.nav-tabs li {
        margin-bottom: 10px;
    }

        ul.ul-edit.navbar.nav-tabs li a {
            border: 0px;
            background: none;
            padding: 10px 20px;
        }

        ul.ul-edit.navbar.nav-tabs li.active a {
            background: #0a819c;
            color: #fff;
        }

.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
}

    .timeline:before {
        top: 0;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 3px;
        background-color: #fff;
        left: 50%;
        margin-left: -1.5px;
    }

    .timeline > li {
        margin-bottom: 20px;
        position: relative;
    }

        .timeline > li:before, .timeline > li:after {
            content: " ";
            display: table;
        }

        .timeline > li:after {
            clear: both;
        }

        .timeline > li:before, .timeline > li:after {
            content: " ";
            display: table;
        }

        .timeline > li:after {
            clear: both;
        }

        .timeline > li > .timeline-panel {
            width: 44%;
            float: left;
            border: 1px solid #d4d4d4;
            border-radius: 2px;
            padding: 20px;
            position: relative;
            -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
            background: #fff;
        }

            .timeline > li > .timeline-panel.primary {
                background: #2e6da4;
                color: #fff;
            }

                .timeline > li > .timeline-panel.primary:after {
                    border-left: 14px solid #2e6da4;
                    border-right: 0 solid #2e6da4;
                }

            .timeline > li > .timeline-panel.success {
                background: #3f903f;
                color: #fff;
            }

                .timeline > li > .timeline-panel.success:after {
                    border-left: 14px solid #3f903f;
                    border-right: 0 solid #3f903f;
                }

            .timeline > li > .timeline-panel.warning {
                background: #f0ad4e;
                color: #fff;
            }

                .timeline > li > .timeline-panel.warning:after {
                    border-left: 14px solid #f0ad4e;
                    border-right: 0 solid #f0ad4e;
                }

            .timeline > li > .timeline-panel.danger {
                background: #d9534f;
                color: #fff;
            }

                .timeline > li > .timeline-panel.danger:after {
                    border-left: 14px solid #d9534f;
                    border-right: 0 solid #d9534f;
                }

            .timeline > li > .timeline-panel.info {
                background: #5bc0de;
                color: #fff;
            }

                .timeline > li > .timeline-panel.info:after {
                    border-left: 14px solid #5bc0de;
                    border-right: 0 solid #5bc0de;
                }

            .timeline > li > .timeline-panel:before {
                position: absolute;
                top: 26px;
                right: -15px;
                display: inline-block;
                border-top: 15px solid transparent;
                border-left: 15px solid #ccc;
                border-right: 0 solid #ccc;
                border-bottom: 15px solid transparent;
                content: " ";
            }

            .timeline > li > .timeline-panel:after {
                position: absolute;
                top: 27px;
                right: -14px;
                display: inline-block;
                border-top: 14px solid transparent;
                border-left: 14px solid #fff;
                border-right: 0 solid #fff;
                border-bottom: 14px solid transparent;
                content: " ";
            }

        .timeline > li > .timeline-badge {
            color: #fff;
            width: 50px;
            height: 50px;
            line-height: 35px;
            font-size: 2.4em;
            text-align: center;
            position: absolute;
            top: 16px;
            left: 50%;
            margin-left: -25px;
            background-color: #999;
            z-index: 100;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
            border: 2px solid #fff;
        }

        .timeline > li.timeline-inverted > .timeline-panel {
            float: right;
        }

            .timeline > li.timeline-inverted > .timeline-panel:before {
                border-left-width: 0;
                border-right-width: 15px;
                left: -15px;
                right: auto;
            }

            .timeline > li.timeline-inverted > .timeline-panel:after {
                border-left-width: 0;
                border-right-width: 14px;
                left: -14px;
                right: auto;
            }

.timeline-badge.primary {
    background-color: #2e6da4 !important;
}

.timeline-badge.success {
    background-color: #3f903f !important;
}

.timeline-badge.warning {
    background-color: #f0ad4e !important;
}

.timeline-badge.danger {
    background-color: #d9534f !important;
}

.timeline-badge.info {
    background-color: #5bc0de !important;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body > p, .timeline-body > ul {
    margin-bottom: 0;
}

    .timeline-body > p + p {
        margin-top: 5px;
    }

@media (max-width:767px) {
    ul.timeline:before {
        left: 40px;
    }

    ul li:not(.timeline-inverted) .timeline-panel:after {
        border-left: 0px !important;
    }

    ul li:not(.timeline-inverted) .timeline-panel.primary:before {
        border-right: 15px solid #2e6da4;
    }

    ul li:not(.timeline-inverted) .timeline-panel.danger:before {
        border-right: 15px solid #d9534f;
    }

    ul.timeline > li > .timeline-panel {
        width: calc(80%);
        width: -moz-calc(80%);
        width: -webkit-calc(80%);
    }

    ul.timeline > li > .timeline-badge {
        left: 15px;
        margin-left: 0;
        top: 16px;
    }

    ul.timeline > li > .timeline-panel {
        float: right;
    }

        ul.timeline > li > .timeline-panel:before {
            border-left-width: 0;
            border-right-width: 15px;
            left: -15px;
            right: auto;
        }

        ul.timeline > li > .timeline-panel:after {
            border-left-width: 0;
            border-right-width: 14px;
            left: -14px;
            right: auto;
        }

        #customerInformation {
          display: none
        }

        .notificationsubjectwrap {
          display: none
        }
}

#one-column .message-item {
    margin-bottom: 25px;
    margin-left: 40px;
    position: relative;
}

    #one-column .message-item .message-inner {
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 0px;
        padding: 10px;
        position: relative;
    }

        #one-column .message-item .message-inner:before {
            border-right: 10px solid #ddd;
            border-style: solid;
            border-width: 10px;
            color: rgba(0, 0, 0, 0);
            content: "";
            display: block;
            height: 0;
            position: absolute;
            left: -20px;
            top: 6px;
            width: 0;
        }

        #one-column .message-item .message-inner:after {
            border-right: 10px solid #fff;
            border-style: solid;
            border-width: 10px;
            color: rgba(0, 0, 0, 0);
            content: "";
            display: block;
            height: 0;
            position: absolute;
            left: -18px;
            top: 6px;
            width: 0;
        }

    #one-column .message-item:before {
        background: #fff;
        border-radius: 2px;
        bottom: -30px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        content: "";
        height: 100%;
        left: -30px;
        position: absolute;
        width: 3px;
    }

    #one-column .message-item:after {
        background: #fff;
        border: 2px solid #ccc;
        border-radius: 0px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        content: "";
        height: 15px;
        left: -36px;
        position: absolute;
        top: 10px;
        width: 15px;
    }

#one-column .clearfix:before, #one-column .clearfix:after {
    content: " ";
    display: table;
}

#one-column .message-item .message-head {
    border-bottom: 1px solid #eee;
    margin-bottom: 8px;
    padding-bottom: 8px;
}

    #one-column .message-item .message-head .avatar {
        margin-right: 20px;
    }

    #one-column .message-item .message-head .user-detail {
        overflow: hidden;
    }

        #one-column .message-item .message-head .user-detail h5 {
            font-size: 16px;
            font-weight: bold;
            margin: 0;
        }

    #one-column .message-item .message-head .post-meta {
        float: left;
        padding: 0 15px 0 0;
    }

        #one-column .message-item .message-head .post-meta > div {
            color: #333;
            font-weight: bold;
            text-align: right;
        }

#one-column .post-meta > div {
    color: #777;
    font-size: 12px;
    line-height: 22px;
}

#one-column .message-item .message-head .post-meta > div {
    color: #333;
    font-weight: bold;
    text-align: right;
}

#one-column .post-meta > div {
    color: #777;
    font-size: 12px;
    line-height: 22px;
}

#one-column .avatar img {
    min-height: 40px;
    max-height: 40px;
}

#one-column .post-meta .qa-message-who-pad {
    margin-left: 10px;
}

#one-column .message-item.blue:after {
    background: #0a819c;
}

#one-column .message-item.blue .message-inner {
    border-color: #0a819c;
}

    #one-column .message-item.blue .message-inner:before {
        border-right-color: #0a819c;
    }

#one-column .message-item.red:after {
    background: #bf4346;
}

#one-column .message-item.red .message-inner {
    border-color: #bf4346;
}

    #one-column .message-item.red .message-inner:before {
        border-right-color: #bf4346;
    }

#one-column .message-item.green:after {
    background: #488c6c;
}

#one-column .message-item.green .message-inner {
    border-color: #488c6c;
}

    #one-column .message-item.green .message-inner:before {
        border-right-color: #488c6c;
    }

#one-column .message-item.orange:after {
    background: #e9662c;
}

#one-column .message-item.orange .message-inner {
    border-color: #e9662c;
}

    #one-column .message-item.orange .message-inner:before {
        border-right-color: #e9662c;
    }

#one-column .message-item.pink:after {
    background: #bf3773;
}

#one-column .message-item.pink .message-inner {
    border-color: #bf3773;
}

    #one-column .message-item.pink .message-inner:before {
        border-right-color: #bf3773;
    }

#one-column .message-item.violet:after {
    background: #9351ad;
}

#one-column .message-item.violet .message-inner {
    border-color: #9351ad;
}

    #one-column .message-item.violet .message-inner:before {
        border-right-color: #9351ad;
    }

.blog-page h1 {
    margin-bottom: 20px;
}

.blog-page .blog-articles {
    padding-bottom: 20px;
}

    .blog-page .blog-articles .blog-img {
        margin-bottom: 10px;
    }

        .blog-page .blog-articles .blog-img img {
            margin-bottom: 12px;
        }

        .blog-page .blog-articles .blog-img ul {
            margin-bottom: 5px;
            margin-left: 0;
        }

            .blog-page .blog-articles .blog-img ul li {
                padding: 0;
            }

                .blog-page .blog-articles .blog-img ul li i {
                    color: #0a819c;
                    margin-right: 3px;
                }

                .blog-page .blog-articles .blog-img ul li a {
                    margin-right: 8px;
                    text-decoration: none;
                }

            .blog-page .blog-articles .blog-img ul.blog-date li i {
                color: #0a819c;
                margin-right: 3px;
            }

            .blog-page .blog-articles .blog-img ul.blog-date li a {
                color: #999;
            }

    .blog-page .blog-articles .blog-article {
        padding-bottom: 20px;
    }

        .blog-page .blog-articles .blog-article h3 {
            margin-top: 0;
        }

    .blog-page .blog-articles ul.blog-tags {
        margin-bottom: 5px;
        margin-left: 0;
    }

        .blog-page .blog-articles ul.blog-tags li {
            padding: 0;
        }

            .blog-page .blog-articles ul.blog-tags li i {
                color: #0a819c;
                margin-right: 3px;
            }

            .blog-page .blog-articles ul.blog-tags li a {
                margin-right: 8px;
                text-decoration: none;
            }

    .blog-page .blog-articles ul.blog-date li i {
        color: #0a819c;
        margin-right: 3px;
    }

    .blog-page .blog-articles ul.blog-date li a {
        color: #999;
    }

.blog-page .blog-sidebar ul li a {
    color: #999;
}

.blog-page .blog-sidebar .blog-images li a img {
    width: 50px;
    height: 50px;
    opacity: 0.6;
    margin: 0 2px 8px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

    .blog-page .blog-sidebar .blog-images li a img:hover {
        opacity: 1;
    }

.blog-page .blog-sidebar .sidebar-tags li {
    padding: 0;
}

    .blog-page .blog-sidebar .sidebar-tags li a {
        color: #fff;
        margin: 0 2px 5px 0;
        display: inline-block;
    }

.blog-page hr {
    margin-bottom: 40px;
}

.blog-page h4.media-heading span {
    font-size: 12px;
}

.blog-page .media .media-body hr {
    margin-bottom: 20px;
}

#external-events .external-event {
    display: inline-block;
    cursor: move;
    margin-bottom: 5px;
    margin-right: 5px;
}

.gallery-pages .list-filter {
    margin-top: 10px;
    margin-bottom: 20px;
}

    .gallery-pages .list-filter li {
        cursor: pointer;
        padding: 6px 15px;
        margin-right: 5px;
        margin-bottom: 5px;
        background: #eee;
        display: inline-block;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

        .gallery-pages .list-filter li.active, .gallery-pages .list-filter li:hover, .gallery-pages .list-filter li:focus {
            background: #0a819c;
            color: #fff;
        }

.gallery-pages .mix-grid .mix {
    display: none;
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
}

    .gallery-pages .mix-grid .mix .hover-effect {
        position: relative;
        border: 0;
        width: 100%;
        height: 100%;
        box-shadow: none;
        overflow: hidden;
    }

        .gallery-pages .mix-grid .mix .hover-effect .img {
            -webkit-transition: all 0.35s ease-in-out;
            transition: all 0.35s ease-in-out;
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
        }

        .gallery-pages .mix-grid .mix .hover-effect .info {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            background: rgba(26, 74, 114, 0.6);
            visibility: hidden;
            opacity: 0;
            -webkit-transition: all 0.35s ease-in-out;
            transition: all 0.35s ease-in-out;
            -webkit-transform: translateY(100%);
            -moz-transform: translateY(100%);
            -ms-transform: translateY(100%);
            -o-transform: translateY(100%);
            transform: translateY(100%);
        }

            .gallery-pages .mix-grid .mix .hover-effect .info h3 {
                text-transform: uppercase;
                color: #fff;
                text-align: center;
                font-size: 16px;
                padding: 10px;
                background: #111111;
                margin: 30px 0 0 0;
            }

            .gallery-pages .mix-grid .mix .hover-effect .info p {
                font-style: italic;
                font-size: 12px;
                position: relative;
                color: #e0e0e0;
                padding: 20px 20px 20px;
                text-align: center;
            }

            .gallery-pages .mix-grid .mix .hover-effect .info a.mix-link {
                color: #fff;
                text-align: center;
                padding: 10px 15px;
                background: #0a819c;
                margin: 20px 10px 20px 0;
                display: inline-block;
            }

            .gallery-pages .mix-grid .mix .hover-effect .info a.mix-zoom {
                color: #fff;
                text-align: center;
                padding: 10px 15px;
                background: #0a819c;
                margin: 20px 10px 20px 0;
                display: inline-block;
            }

        .gallery-pages .mix-grid .mix .hover-effect:hover .img {
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            -o-transform: scale(1.2);
            transform: scale(1.2);
        }

        .gallery-pages .mix-grid .mix .hover-effect:hover .info {
            visibility: visible;
            opacity: 1;
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0);
        }

.mail-content {
    overflow-x: hidden;
    overflow-y: auto;
    height: 657px;
}

    .mail-content .mail-sender {
        width: 100%;
        display: inline-block;
        margin: 0 0 20px 0;
        border-bottom: 1px solid #EFF2F7;
        padding: 10px 0;
    }

        .mail-content .mail-sender .date {
            line-height: 30px;
            margin-bottom: 0;
            text-align: right;
        }

    .mail-content .mail-view {
        margin-bottom: 25px;
    }

    .mail-content .mail-attachment ul {
        padding: 0;
    }

        .mail-content .mail-attachment ul li {
            float: left;
            width: 100px;
            margin-right: 15px;
            margin-top: 15px;
            list-style: none;
        }

            .mail-content .mail-attachment ul li:hover {
                background: #f7f8f9;
            }

            .mail-content .mail-attachment ul li a {
                color: #999;
            }

            .mail-content .mail-attachment ul li .thumb-attach img {
                width: 100px;
                height: auto;
                margin-bottom: 10px;
            }

            .mail-content .mail-attachment ul li .link {
                color: #0a819c;
            }

#invoice-page .panel {
    border-radius: 0;
}

    #invoice-page .panel .panel-body {
        padding: 30px;
    }

        #invoice-page .panel .panel-body .invoice-title {
            float: right;
            text-align: right;
        }

            #invoice-page .panel .panel-body .invoice-title h2 {
                margin-top: 0;
            }

        #invoice-page .panel .panel-body .logo {
            font-weight: bold;
            margin-top: 0;
        }

        #invoice-page .panel .panel-body hr {
            margin: 30px 0;
        }

.box-placeholder {
    margin-bottom: 15px;
    padding: 20px;
    border: 1px solid #e5e5e5;
    background: #fff;
    color: #444;
}

.state-error + em {
    display: block;
    margin-top: 6px;
    padding: 0 1px;
    font-style: normal;
    font-size: 11px;
    line-height: 15px;
    color: #d9534f;
}

.state-success + em {
    display: block;
    margin-top: 6px;
    padding: 0 1px;
    font-style: normal;
    font-size: 11px;
    line-height: 15px;
    color: #5cb85c;
}

.state-error input, .state-error select {
    background: #f2dede;
}

.state-success input, .state-success select {
    background: #dff0d8;
}

.note-success {
    color: #5cb85c;
}

.radio-inline, .checkbox-inline, .checkbox, .radio {
    padding-left: 0;
    margin: 0;
}

    .checkbox label, .radio label {
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 0;
    }

.form-horizontal.form-bordered .radio, .form-horizontal.form-bordered .checkbox, .form-horizontal.form-bordered .radio-inline, .form-horizontal.form-bordered .checkbox-inline {
    padding-top: 0;
}

.social-icons li a {
    border: 1px solid #999;
    border-radius: 50%;
    color: #999;
    padding: 10px;
}

    .social-icons li a:hover, .social-icons li a:focus {
        border-color: #0a819c;
        background: #0a819c;
        color: #fff;
    }

.member-team {
    background-color: #f9f9f9;
    float: left;
    padding: 5px;
    margin-bottom: 10px;
    max-width: 100%;
}

    .member-team h3 {
        margin-top: 10px;
    }

        .member-team h3 small {
            color: #ababab;
            display: block;
            margin-top: 5px;
            font-size: 13px;
        }

#faq .panel-group .panel {
    margin-bottom: 10px;
}

    #faq .panel-group .panel .panel-heading {
        font-size: 14px;
        padding-top: 0;
        padding-bottom: 0;
    }

        #faq .panel-group .panel .panel-heading a {
            color: #999;
        }

            #faq .panel-group .panel .panel-heading a strong {
                margin-right: 10px;
            }

        #faq .panel-group .panel .panel-heading:hover, #faq .panel-group .panel .panel-heading:focus {
            background: #e5e5e5;
        }

    #faq .panel-group .panel .panel-body strong:first-child {
        margin-right: 10px;
    }

.accordion-toggle {
    display: block;
    line-height: 22px;
    padding: 10px 0;
    position: relative;
}

.row .row-merge {
    margin: 0;
}

    .row .row-merge [class*=col-] {
        padding: 0;
    }

        .row .row-merge [class*=col-] .pricing-widget {
            position: relative;
            border: 0;
            cursor: pointer;
            margin: 20px 0;
            -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) !important;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) !important;
        }

            .row .row-merge [class*=col-] .pricing-widget .pricing-head {
                background: #5cb85c;
                padding: 6px 20px;
                font-size: 18px;
                text-align: center;
                color: #fff;
            }

            .row .row-merge [class*=col-] .pricing-widget .pricing-body {
                background: #fff;
            }

            .row .row-merge [class*=col-] .pricing-widget .pricing-cost {
                background: #4cae4c;
                text-align: center;
                padding: 20px;
                border-bottom: 1px solid #efefef;
                transition: all .3s ease;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -ms-transition: all .3s ease;
                -o-transition: all .3s ease;
                font-size: 18px;
                color: #fff;
                min-height: 125px;
            }

                .row .row-merge [class*=col-] .pricing-widget .pricing-cost strong {
                    font-size: 30px;
                }

            .row .row-merge [class*=col-] .pricing-widget .pricing-list {
                list-style: none;
                padding: 0;
                margin: 0;
            }

                .row .row-merge [class*=col-] .pricing-widget .pricing-list li {
                    padding: 10px;
                    border-bottom: 1px solid #efefef;
                }

                    .row .row-merge [class*=col-] .pricing-widget .pricing-list li:last-child {
                        min-height: 84px;
                        padding-top: 30px;
                        box-shadow: none;
                        -moz-box-shadow: none;
                        -webkit-box-shadow: none;
                        border-bottom: none;
                    }

            .row .row-merge [class*=col-] .pricing-widget:not(.pricing-title).active, .row .row-merge [class*=col-] .pricing-widget:not(.pricing-title):hover {
                -webkit-box-shadow: 0 3px 25px -4px rgba(0, 0, 0, 0.9) !important;
                box-shadow: 0 3px 25px -4px rgba(0, 0, 0, 0.9) !important;
            }

            .row .row-merge [class*=col-] .pricing-widget.active {
                z-index: 2;
            }

.ribbon-wrapper {
    position: absolute;
    width: 75px;
    height: 75px;
    overflow: hidden;
    top: -1px;
    right: 14px;
}

    .ribbon-wrapper .ribbon-inner {
        display: block;
        position: relative;
        padding: 5px 0;
        color: #fff;
        background-color: #bf4346;
        font-size: 13px;
        line-height: 17px;
        text-align: center;
        width: 107px;
        top: 11px;
        left: -5px;
        text-shadow: 0 1px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.75) !important;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.75) !important;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
    }

.the-price {
    padding: 20px;
    margin: 0;
}

    .the-price h1 {
        margin-bottom: 0;
    }

    .the-price .subscript {
        font-size: 14px;
    }

#faq .panel {
    border: 0;
}

#totop {
    position: fixed;
    bottom: 0;
    right: 0;
    display: none;
    z-index: 9999;
    background: transparent;
    text-align: center;
    height: 64px;
    width: 50px;
    background: #0074bd;
}

    #totop i {
        color: #fff;
        line-height: 63px;
        font-size: 33px;
    }

    #totop:hover {
        background: #f46f34;
    }

        #totop:hover i {
            color: #fff;
        }

.option-demo {
    position: relative;
}

.demo-layout {
    background-color: #fff;
    padding: 5px 15px;
    position: absolute;
    top: -30px;
    left: 0;
    z-index: 9999;
    border: 1px solid #e5e5e5;
    cursor: pointer;
}

#sidebar-hover #topbar .navbar-header {
    width: 160px;
}

#sidebar-hover #wrapper #page-wrapper {
    margin-left: 160px;
}

#sidebar-hover #wrapper #sidebar {
    width: 160px;
}

    #sidebar-hover #wrapper #sidebar ul.menu-hover {
        width: 160px;
        position: absolute;
        top: 50px;
        display: block;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

        #sidebar-hover #wrapper #sidebar ul.menu-hover > li.active a {
            opacity: 0.95;
        }

        #sidebar-hover #wrapper #sidebar ul.menu-hover > li a {
            background-color: #1b4283;
            color: #fff;
            padding: 15px;
            position: relative;
        }

            #sidebar-hover #wrapper #sidebar ul.menu-hover > li a span.menu-title {
                margin-left: 10px;
            }

            #sidebar-hover #wrapper #sidebar ul.menu-hover > li a:after {
                content: '';
                border: 0;
            }

            #sidebar-hover #wrapper #sidebar ul.menu-hover > li a:hover {
                opacity: 0.95;
            }

        #sidebar-hover #wrapper #sidebar ul.menu-hover > li ul.dropdown-menu {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }

            #sidebar-hover #wrapper #sidebar ul.menu-hover > li ul.dropdown-menu > li:first-child > a:before {
                content: '';
                display: block;
                position: absolute;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 8px 8px 8px 0;
                border-color: transparent #086176 transparent transparent;
                left: -8px;
                top: 50%;
                margin-top: -8px;
            }

            #sidebar-hover #wrapper #sidebar ul.menu-hover > li ul.dropdown-menu > li a {
                background-color: #086176;
            }

                #sidebar-hover #wrapper #sidebar ul.menu-hover > li ul.dropdown-menu > li a:hover {
                    opacity: 0.95;
                }

            #sidebar-hover #wrapper #sidebar ul.menu-hover > li ul.dropdown-menu > li ul.dropdown-menu {
                -webkit-box-shadow: none !important;
                box-shadow: none !important;
            }

                #sidebar-hover #wrapper #sidebar ul.menu-hover > li ul.dropdown-menu > li ul.dropdown-menu > li:first-child > a:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 0px;
                    height: 0px;
                    border-style: solid;
                    border-width: 8px 8px 8px 0;
                    border-color: transparent #0b89a6 transparent transparent;
                    left: -8px;
                    top: 50%;
                    margin-top: -8px;
                }

                #sidebar-hover #wrapper #sidebar ul.menu-hover > li ul.dropdown-menu > li ul.dropdown-menu > li a {
                    background-color: #0b89a6;
                }

                    #sidebar-hover #wrapper #sidebar ul.menu-hover > li ul.dropdown-menu > li ul.dropdown-menu > li a:hover {
                        opacity: 0.95;
                    }

#sidebar-hover .dropdown-submenu > .dropdown-menu {
    margin-left: 0;
}

.mail-box {
    margin-bottom: 0;
}

    .mail-box .list-group-item-action:nth-child(odd) {
        background-color: #f8f8f8;
    }

    .mail-box .list-group-item-action {
        border: 0;
    }

        .mail-box .list-group-item-action:hover {
            color: #999;
        }

        .mail-box .list-group-item-action .time-badge {
            float: right;
            font-style: italic;
            color: #999;
        }

        .mail-box .list-group-item-action.active, .mail-box .list-group-item-action:hover, .mail-box .list-group-item-action:focus {
            background-color: #ffc !important;
            color: #999 !important;
        }

.last-col {
    overflow: hidden !important;
}

.pdn {
    padding: 0 !important;
}

.pdx {
    padding: 3px;
}

.pdm {
    padding: 10px;
}

.pdl {
    padding: 20px;
}

.pdxl {
    padding: 30px;
}

.pdxxl {
    padding: 40px;
}

.ptn, .pvn, .pan {
    padding-top: 0 !important;
}

.ptx, .pvx, .pax {
    padding-top: 3px !important;
}

.pts, .pvs, .pas {
    padding-top: 5px !important;
}

.ptm, .pvm, .pam {
    padding-top: 10px !important;
}

.ptl, .pvl, .pal {
    padding-top: 20px !important;
}

.ptxl, .pvxl, .paxl {
    padding-top: 30px !important;
}

.ptxxl, .pvxxl, .paxxl {
    padding-top: 40px !important;
}

.prn, .phn, .pan {
    padding-right: 0 !important;
}

.prx, .phx, .pax {
    padding-right: 3px !important;
}

.prs, .phs, .pas {
    padding-right: 5px !important;
}

.prm, .phm, .pam {
    padding-right: 10px !important;
}

.prl, .phl, .pal {
    padding-right: 20px !important;
}

.prxl, .phxl, .paxl {
    padding-right: 30px !important;
}

.prxxl, .phxxl, .paxxl {
    padding-right: 40px !important;
}

.pbn, .pvn, .pan {
    padding-bottom: 0 !important;
}

.pbx, .pvx, .pax {
    padding-bottom: 3px !important;
}

.pbs, .pvs, .pas {
    padding-bottom: 5px !important;
}

.pbm, .pvm, .pam {
    padding-bottom: 10px !important;
}

.pbl, .pvl, .pal {
    padding-bottom: 20px !important;
}

.pbxl, .pvxl, .paxl {
    padding-bottom: 30px !important;
}

.pbxxl, .pvxxl, .paxxl {
    padding-bottom: 40px !important;
}

.pln, .phn, .pan {
    padding-left: 0 !important;
}

.plx, .phx, .pax {
    padding-left: 3px !important;
}

.pls, .phs, .pas {
    padding-left: 5px !important;
}

.plm, .phm, .pam {
    padding-left: 10px !important;
}

.pll, .phl, .pal {
    padding-left: 20px !important;
}

.plxl, .phxl, .paxl {
    padding-left: 30px !important;
}

.plxxl, .phxxl, .paxxl {
    padding-left: 40px !important;
}

.mtn, .mvn, .man {
    margin-top: 0px !important;
}

.mtx, .mvx, .max {
    margin-top: 3px !important;
}

.mts, .mvs, .mas {
    margin-top: 5px !important;
}

.mtm, .mvm, .mam {
    margin-top: 10px !important;
}

.mtl, .mvl, .mal {
    margin-top: 20px !important;
}

.mtxl, .mvxl, .maxl {
    margin-top: 30px !important;
}

.mtxxl, .mvxxl, .maxxl {
    margin-top: 40px !important;
}

.mrn, .mhn, .man {
    margin-right: 0px !important;
}

.mrx, .mhx, .max {
    margin-right: 3px !important;
}

.mrs, .mhs, .mas {
    margin-right: 5px !important;
}

.mrm, .mhm, .mam {
    margin-right: 10px !important;
}

.mrl, .mhl, .mal {
    margin-right: 20px !important;
}

.mrxl, .mhxl, .maxl {
    margin-right: 30px !important;
}

.mrxxl, .mhxxl, .maxxl {
    margin-right: 40px !important;
}

.mbn, .mvn, .man {
    margin-bottom: 0px !important;
}

.mbx, .mvx, .max {
    margin-bottom: 3px !important;
}

.mbs, .mvs, .mas {
    margin-bottom: 5px !important;
}

.mbm, .mvm, .mam {
    margin-bottom: 10px !important;
}

.mbl, .mvl, .mal {
    margin-bottom: 20px !important;
}

.mbxl, .mvxl, .maxl {
    margin-bottom: 30px !important;
}

.mbxxl, .mvxxl, .maxxl {
    margin-bottom: 40px !important;
}

.mln, .mhn, .man {
    margin-left: 0px !important;
}

.mlx, .mhx, .max {
    margin-left: 3px !important;
}

.mls, .mhs, .mas {
    margin-left: 5px !important;
}

.mlm, .mhm, .mam {
    margin-left: 10px !important;
}

.mll, .mhl, .mal {
    margin-left: 20px !important;
}

.mlxl, .mhxl, .maxl {
    margin-left: 30px !important;
}

.mlxxl, .mhxxl, .maxxl {
    margin-left: 40px !important;
}

/************************/

/***** Jquery Pace *****/
.pace .pace-progress {
    background-color: #0a819c;
}

/***** Jquery Pace *****/

/***********************/
.legendColorBox {
    padding: 3px 0;
}

    .legendColorBox div {
        border-radius: 50%;
        margin-right: 5px;
    }

        .legendColorBox div div {
            margin-right: 0;
        }

.ui-state-default {
    border: 0;
    background: #0a819c;
    color: #fff;
}

.ui-widget-content {
    background: #fff;
    border: 1px solid #e5e5e5;
}

    .ui-widget-content .ui-state-default {
        border: 0;
        background: #0a819c;
        color: #fff;
        cursor: pointer;
    }

        .ui-widget-content .ui-state-default:hover, .ui-widget-content .ui-state-default:focus {
            background: #086d84;
        }

.ui-widget-header {
    background: #0a819c;
}

    .ui-widget-header .ui-state-default {
        border: 0;
        background: #0a819c;
        color: #fff;
    }

.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: 3px solid #fefefe;
}

.ui-slider.ui-slider-horizontal .ui-slider-handle {
    top: -0.9em;
}

.ui-slider.ui-slider-vertical .ui-slider-handle {
    left: -0.85em;
}

#slider-multi span {
    height: 120px;
    float: left;
    margin: 20px;
}

.ui-slider-horizontal {
    height: 0.45em;
}

.ui-slider-vertical {
    width: 0.45em;
}

.example-val:before {
    content: "Value:";
    font-weight: bold;
}

.irs-line-mid, .irs-line-left, .irs-line-right, .irs-diapason, .irs-slider {
    background: #E5E5E5;
}

.irs-slider {
    width: 1.5em;
    height: 1.5em;
    top: 19px;
    border: 1px solid #fefefe;
    background: #777;
    border-radius: 50%;
}

.irs-diapason {
    background: #0a819c;
}

.irs {
    height: 40px;
}

.irs-with-grid {
    height: 60px;
}

.irs-line {
    height: 8px;
    top: 25px;
}

.irs-line-left {
    height: 8px;
}

.irs-line-mid {
    height: 8px;
}

.irs-line-right {
    height: 8px;
}

.irs-diapason {
    height: 8px;
    top: 25px;
}

.irs-min, .irs-max {
    color: #999;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    top: 0;
    padding: 1px 3px;
    background: rgba(0, 0, 0, 0.1);
}

.lt-ie9 .irs-min, .lt-ie9 .irs-max {
    background: #ccc;
}

.irs-from, .irs-to, .irs-single {
    color: #fff;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 3px;
    background: rgba(0, 0, 0, 0.3);
}

.lt-ie9 .irs-from, .lt-ie9 .irs-to, .lt-ie9 .irs-single {
    background: #999;
}

.irs-grid-pol {
    background: #999;
}

.irs-grid-text {
    color: #999;
}

.jquery-notific8-message {
    font-size: 13px;
}

[class*="jquery-notific8"], [class*="jquery-notific8"]:after, [class*="jquery-notific8"]:before {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.right .jquery-notific8-close-sticky span, .left .jquery-notific8-close-sticky span {
    font-size: 10px;
}

.jquery-notific8-heading {
    font-weight: 300;
    font-size: 16px;
}

.colors {
    clear: both;
    padding: 24px 0 9px;
}

.skin-line .colors {
    padding-top: 28px;
}

.colors strong {
    float: left;
    line-height: 20px;
    margin-right: 20px;
}

.colors li {
    position: relative;
    float: left;
    width: 16px;
    height: 16px;
    margin: 2px 1px 0 0;
    background: #000;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: .5;
    -webkit-transition: opacity .2s;
    -moz-transition: opacity .2s;
    -ms-transition: opacity .2s;
    -o-transition: opacity .2s;
    transition: opacity .2s;
}

    .colors li:hover {
        filter: alpha(opacity=100);
        opacity: 1;
    }

    .colors li.active {
        height: 20px;
        margin-top: 0;
        filter: alpha(opacity=75);
        opacity: 0.75;
    }

    .colors li.red {
        background: #d54e21;
    }

    .colors li.green {
        background: #78a300;
    }

    .colors li.blue {
        background: #0e76a8;
    }

    .colors li.aero {
        background: #9cc2cb;
    }

    .colors li.grey {
        background: #73716e;
    }

    .colors li.orange {
        background: #ff7700;
    }

    .colors li.yellow {
        background: #ffcc00;
    }

    .colors li.pink {
        background: #ff66b5;
    }

    .colors li.purple {
        background: #6a5a8c;
    }

.sticky-header {
    z-index: 999;
}

.slimScrollDiv {
    float: left;
}

.slimScrollRail {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.table tbody tr.calendar-month-header:first-child th {
    border-top: 0;
    background: #0a819c;
    color: #fff;
    padding: 12px 0;
}

    .table tbody tr.calendar-month-header:first-child th .calendar-month-navigation {
        padding-top: 0;
    }

    .table tbody tr.calendar-month-header:first-child th span {
        padding-bottom: 0;
    }

.table tbody tr td {
    border-top: 1px solid #efefef;
}

.ticker-wrapper.has-js {
    margin: 0;
    padding: 0;
    float: left;
    background: transparent;
    font-size: 13px;
}

.ticker {
    display: block;
    position: relative;
    overflow: hidden;
    background: transparent;
    margin: 0;
    padding: 0;
}

.ticker-title {
    background-color: transparent;
}

.ticker-content {
    color: #fff;
    background: transparent;
    font-weight: normal;
    padding-top: 8px;
}

    .ticker-content a {
        color: #999;
    }

        .ticker-content a:hover, .ticker-content a:focus {
            color: #999;
            text-decoration: none;
        }

.ticker-swipe {
    width: 100%;
    height: 42px;
    background: #405058;
}

    .ticker-swipe span {
        margin-left: 1px;
        background-color: transparent;
        border-bottom: 3px solid #999;
        height: 21px;
        width: 7px;
        display: block;
    }

.news-update-box {
    float: left;
    padding: 9px 20px;
    line-height: 30px;
}

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus {
    border-color: #999;
    box-shadow: none;
    outline: 0 none;
}

a:focus, .btn:focus {
    outline: 0 !important;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}

.note {
    margin: 0 0 20px 0;
    padding: 15px 30px 15px 15px;
    border-left: 3px solid #e5e5e5;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

    .note h1, .note h2, .note h3, .note h4 {
        margin-top: 0;
    }

    .note p:last-child {
        margin-bottom: 0;
    }

    .note code, .note .highlight {
        background-color: #fff;
    }

.note-success {
    border-color: #5cb85c;
    background: #dff0d8;
}

    .note-success .box-heading {
        color: #5cb85c;
    }

.note-warning {
    border-color: #f0ad4e;
    background: #fcf8e3;
}

    .note-warning .box-heading {
        color: #f0ad4e;
    }

.note-info {
    border-color: #5bc0de;
    background: #d9edf7;
}

    .note-info .box-heading {
        color: #5bc0de;
    }

.note-danger {
    border-color: #d9534f;
    background: #f2dede;
}

    .note-danger .box-heading {
        color: #d9534f;
    }

.badge {
    font-size: 11px !important;
    font-weight: 300;
    height: 18px;
    padding: 3px 6px 3px 6px;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    border-radius: 12px !important;
    text-shadow: none !important;
    text-align: center;
    vertical-align: middle;
    background-color: #bcbcbc;
}

.label {
    font-size: 11px;
    font-weight: 300;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.badge-default, .label-default {
    background-color: #999 !important;
}

.badge-primary, .label-primary {
    background-color: #0a819c !important;
}

.badge-red, .label-red {
    background-color: #bf4346 !important;
}

.badge-orange, .label-orange {
    background-color: #e9662c !important;
}

.badge-green, .label-green {
    background-color: #488c6c !important;
}

.badge-yellow, .label-yellow {
    background-color: #f2994b !important;
}

.badge-blue, .label-blue {
    background-color: #0a819c !important;
}

.badge-violet, .label-violet {
    background-color: #9351ad !important;
}

.badge-pink, .label-pink {
    background-color: #bf3773 !important;
}

.badge-grey, .label-grey {
    background-color: #1b4283 !important;
}

.badge-dark, .label-dark {
    background-color: #594857 !important;
}

.label-success, .badge-success {
    background-color: #8A8A98;
    background-image: none !important;
}

.label-warning, .badge-warning {
    background-color: #f0ad4e;
    background-image: none !important;
}

.label-danger, .badge-danger {
    background-color: #d9534f;
    background-image: none !important;
}

.label-info, .badge-info {
    background-color: #5bc0de;
    background-image: none !important;
}

.navbar.nav-pills > li > a > .badge {
    margin-top: -2px;
}

.navbar.nav-stacked > li > a > .badge {
    margin-top: 1px;
    margin-bottom: 0px;
}

a.list-group-item-action.active > .badge, .nav-pills > .active > a > .badge {
    color: #0a819c;
}

.pagination {
    margin: 0;
    width: 100%;
    display: block;
    text-align: right;
}

.pagination li {
    display: inline-block;
}

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.page-content .panel.full-height {
    max-height: none !important;
}

.page-content .panel:not(.panel-blue){
    min-height: 250px;
    max-height: 300px;
}
.page-content .panel.gaugecontrol {
    max-height: none;
    min-width: 160px;
}
.page-content .panel.gaugecontrol.apply-max-height {
        max-height: 300px !important;

}
.page-content .fullscreen.fullscreen-done .panel.gaugecontrol.apply-max-height {
    max-height: none !important;
}
.page-content.details .panel:not(.panel-blue) {
    min-height: 250px;
    max-height: none;
}

.page-content .panel .panel {
    position: relative;
    min-height: inherit;
    max-height: 100%;
}


.panel > .panel-heading,
.panel-content > .panel-heading {
    padding: 5px 0 5px 15px;
    height: 40px;
    border-color: #e5e5e5;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items:center;
}



.panel .panel > .panel-heading {
    font-size: 13px;
    font-weight: bold;
}

.panel-heading ~ .panel-body {
    border-top: solid 1px #e5e5e5;
}

.panel-body .panel-body {
    border-top: none;
    padding: 0;
}

.panel > .panel-footer {
    font-size: 18px;
    padding: 7px 15px;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

.panel.panel-primary {
    border-color: #0a819c;
}

    .panel.panel-primary > .panel-heading {
        color: #fff;
        background: #0a819c;
        border-color: #0a819c !important;
    }

    .panel.panel-primary > .panel-footer {
        color: #fff;
        background: #0a819c;
        border-color: #0a819c !important;
    }

.panel.panel-red {
    border-color: #bf4346;
}

    .panel.panel-red > .panel-heading {
        color: #fff;
        background: #bf4346;
        border-color: #bf4346 !important;
    }

    .panel.panel-red > .panel-footer {
        color: #fff;
        background: #bf4346;
        border-color: #bf4346 !important;
    }

.panel.panel-orange {
    border-color: #e9662c;
}

    .panel.panel-orange > .panel-heading {
        color: #fff;
        background: #e9662c;
        border-color: #e9662c !important;
    }

    .panel.panel-orange > .panel-footer {
        color: #fff;
        background: #e9662c;
        border-color: #e9662c !important;
    }

.panel.panel-green {
    border-color: #488c6c;
}

    .panel.panel-green > .panel-heading {
        color: #fff;
        background: #488c6c;
        border-color: #488c6c !important;
    }

    .panel.panel-green > .panel-footer {
        color: #fff;
        background: #488c6c;
        border-color: #488c6c !important;
    }

.panel.panel-yellow {
    border-color: #f2994b;
}

    .panel.panel-yellow > .panel-heading {
        color: #fff;
        background: #f2994b;
        border-color: #f2994b !important;
    }

    .panel.panel-yellow > .panel-footer {
        color: #fff;
        background: #f2994b;
        border-color: #f2994b !important;
    }

.panel.panel-blue {
    border-color: #0074bd;
}

    .panel.panel-blue > .panel-heading {
        color: #fff;
        background: #0074bd;
        border-color: #0074bd !important;
    }

    .panel.panel-blue > .panel-footer {
        color: #fff;
        background: #0074bd;
        border-color: #0074bd !important;
    }

.panel.panel-pink {
    border-color: #bf3773;
}

    .panel.panel-pink > .panel-heading {
        color: #fff;
        background: #bf3773;
        border-color: #bf3773 !important;
    }

    .panel.panel-pink > .panel-footer {
        color: #fff;
        background: #bf3773;
        border-color: #bf3773 !important;
    }

.panel.panel-violet {
    border-color: #9351ad;
}

    .panel.panel-violet > .panel-heading {
        color: #fff;
        background: #9351ad;
        border-color: #9351ad !important;
    }

    .panel.panel-violet > .panel-footer {
        color: #fff;
        background: #9351ad;
        border-color: #9351ad !important;
    }

.panel.panel-grey {
    border-color: #1b4283;
}

    .panel.panel-grey > .panel-heading {
        color: #fff;
        background: #1b4283;
        border-color: #1b4283 !important;
    }

    .panel.panel-grey > .panel-footer {
        color: #fff;
        background: #1b4283;
        border-color: #1b4283 !important;
    }

.panel.panel-dark {
    border-color: #594857;
}

    .panel.panel-dark > .panel-heading {
        color: #fff;
        background: #594857;
        border-color: #594857 !important;
    }

    .panel.panel-dark > .panel-footer {
        color: #fff;
        background: #594857;
        border-color: #594857 !important;
    }

.panel.panel-white {
    border-color: #efefef;
}

    .panel.panel-white > .panel-heading {
        border-bottom: 1px solid #faf9fb;
        color: #999;
        background: #fff;
    }

    .panel.panel-white > .panel-footer {
        border-top: 1px solid #faf9fb;
        color: #999;
        background: #fff;
    }


.btn {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    outline: none !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
    transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
}

    .btn.btn-square {
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
    }

.btn-outlined {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

    .btn-outlined.btn-outline-secondary {
        background: none;
        border: 1px solid #999;
        color: #999;
    }

    .btn-outlined.btn-primary {
        background: none;
        border: 1px solid #0a819c;
        color: #0a819c;
    }

    .btn-outlined.btn-success {
        background: none;
        border: 1px solid #5cb85c;
        color: #5cb85c;
    }

    .btn-outlined.btn-warning {
        background: none;
        border: 1px solid #f0ad4e;
        color: #f0ad4e;
    }

    .btn-outlined.btn-info {
        background: none;
        border: 1px solid #5bc0de;
        color: #5bc0de;
    }

    .btn-outlined.btn-danger {
        background: none;
        border: 1px solid #d9534f;
        color: #d9534f;
    }

    .btn-outlined.btn-red {
        background: none;
        border: 1px solid #bf4346;
        color: #bf4346;
    }

    .btn-outlined.btn-orange {
        background: none;
        border: 1px solid #e9662c;
        color: #e9662c;
    }

    .btn-outlined.btn-green {
        background: none;
        border: 1px solid #488c6c;
        color: #488c6c;
    }

    .btn-outlined.btn-yellow {
        background: none;
        border: 1px solid #f2994b;
        color: #f2994b;
    }

    .btn-outlined.btn-blue {
        background: none;
        border: 1px solid #0a819c;
        color: #0a819c;
    }

    .btn-outlined.btn-pink {
        background: none;
        border: 1px solid #bf3773;
        color: #bf3773;
    }

    .btn-outlined.btn-violet {
        background: none;
        border: 1px solid #9351ad;
        color: #9351ad;
    }

    .btn-outlined.btn-grey {
        background: none;
        border: 1px solid #1b4283;
        color: #1b4283;
    }

    .btn-outlined.btn-dark {
        background: none;
        border: 1px solid #594857;
        color: #594857;
    }

.btn-outline-secondary {
    /*  color:#fff;
    background-color:#333;
    border-color:#333;*/
}

    .btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active, .open .dropdown-toggle.btn-outline-secondary {
        /*  color:#fff;
    background-color:#f46f34;
    border-color:#f46f34;*/
    }
    .btn-filter-only{
        color:#0074bd !important;
        border: 2px solid #0074bd !important;
        background:#fff !important;
    }
    .btn-filter-only:hover{
        background:#0074bd !important;
        color:#fff !important;
    }
    .btn-outline-secondary:active, .btn-outline-secondary.active, .open .dropdown-toggle.btn-outline-secondary {
        background-image: none;
    }

    .btn-outline-secondary.disabled, .btn-outline-secondary[disabled], fieldset[disabled] .btn-outline-secondary, .btn-outline-secondary.disabled:hover, .btn-outline-secondary[disabled]:hover, fieldset[disabled] .btn-outline-secondary:hover, .btn-outline-secondary.disabled:focus, .btn-outline-secondary[disabled]:focus, fieldset[disabled] .btn-outline-secondary:focus, .btn-outline-secondary.disabled:active, .btn-outline-secondary[disabled]:active, fieldset[disabled] .btn-outline-secondary:active, .btn-outline-secondary.disabled.active, .btn-outline-secondary[disabled].active, fieldset[disabled] .btn-outline-secondary.active {
        background-color: #efefef;
        border-color: #e5e5e5;
    }

.btn-primary {
    color: #fff;
    background-color: #0a819c;
    border-color: #097992;
}

    .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
        color: #fff;
        background-color: #086176;
        border-color: #064a59;
    }

    .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
        background-image: none;
    }

    .btn-primary.disabled, .btn-primary[disabled], fieldset[disabled] .btn-primary, .btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover, .btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus, .btn-primary.disabled:active, .btn-primary[disabled]:active, fieldset[disabled] .btn-primary:active, .btn-primary.disabled.active, .btn-primary[disabled].active, fieldset[disabled] .btn-primary.active {
        background-color: #0a819c;
        border-color: #097992;
    }

.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #55b555;
}

    .btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success {
        color: #fff;
        background-color: #47a447;
        border-color: #3e8f3e;
    }

    .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success {
        background-image: none;
    }

    .btn-success.disabled, .btn-success[disabled], fieldset[disabled] .btn-success, .btn-success.disabled:hover, .btn-success[disabled]:hover, fieldset[disabled] .btn-success:hover, .btn-success.disabled:focus, .btn-success[disabled]:focus, fieldset[disabled] .btn-success:focus, .btn-success.disabled:active, .btn-success[disabled]:active, fieldset[disabled] .btn-success:active, .btn-success.disabled.active, .btn-success[disabled].active, fieldset[disabled] .btn-success.active {
        background-color: #5cb85c;
        border-color: #55b555;
    }

.btn-info {
    color: #fff;
    background-color: #0074bd;
    border-color: #0074bd;
}

    .btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {
        color: #fff;
        background-color: #f46f34;
        border-color: #f46f34;
        opacity: 1;
    }

    .btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {
        background-image: none;
    }

    .btn-info.disabled, .btn-info[disabled], fieldset[disabled] .btn-info, .btn-info.disabled:hover, .btn-info[disabled]:hover, fieldset[disabled] .btn-info:hover, .btn-info.disabled:focus, .btn-info[disabled]:focus, fieldset[disabled] .btn-info:focus, .btn-info.disabled:active, .btn-info[disabled]:active, fieldset[disabled] .btn-info:active, .btn-info.disabled.active, .btn-info[disabled].active, fieldset[disabled] .btn-info.active {
        background-color: #5bc0de;
        border-color: #53bddc;
    }

.btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #efa945;
}

    .btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning {
        color: #fff;
        background-color: #ed9c28;
        border-color: #e38d13;
    }

    .btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning {
        background-image: none;
    }

    .btn-warning.disabled, .btn-warning[disabled], fieldset[disabled] .btn-warning, .btn-warning.disabled:hover, .btn-warning[disabled]:hover, fieldset[disabled] .btn-warning:hover, .btn-warning.disabled:focus, .btn-warning[disabled]:focus, fieldset[disabled] .btn-warning:focus, .btn-warning.disabled:active, .btn-warning[disabled]:active, fieldset[disabled] .btn-warning:active, .btn-warning.disabled.active, .btn-warning[disabled].active, fieldset[disabled] .btn-warning.active {
        background-color: #f0ad4e;
        border-color: #efa945;
    }

.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d74b47;
}

    .btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger {
        color: #fff;
        background-color: #d2322d;
        border-color: #b92c28;
    }

    .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger {
        background-image: none;
    }

    .btn-danger.disabled, .btn-danger[disabled], fieldset[disabled] .btn-danger, .btn-danger.disabled:hover, .btn-danger[disabled]:hover, fieldset[disabled] .btn-danger:hover, .btn-danger.disabled:focus, .btn-danger[disabled]:focus, fieldset[disabled] .btn-danger:focus, .btn-danger.disabled:active, .btn-danger[disabled]:active, fieldset[disabled] .btn-danger:active, .btn-danger.disabled.active, .btn-danger[disabled].active, fieldset[disabled] .btn-danger.active {
        background-color: #d9534f;
        border-color: #d74b47;
    }

.btn-red {
    color: #fff;
    background-color: #bf4346;
    border-color: #b93f42;
}

    .btn-red:hover, .btn-red:focus, .btn-red:active, .btn-red.active, .open .dropdown-toggle.btn-red {
        color: #fff;
        background-color: #a2373a;
        border-color: #8b2f32;
    }

    .btn-red:active, .btn-red.active, .open .dropdown-toggle.btn-red {
        background-image: none;
    }

    .btn-red.disabled, .btn-red[disabled], fieldset[disabled] .btn-red, .btn-red.disabled:hover, .btn-red[disabled]:hover, fieldset[disabled] .btn-red:hover, .btn-red.disabled:focus, .btn-red[disabled]:focus, fieldset[disabled] .btn-red:focus, .btn-red.disabled:active, .btn-red[disabled]:active, fieldset[disabled] .btn-red:active, .btn-red.disabled.active, .btn-red[disabled].active, fieldset[disabled] .btn-red.active {
        background-color: #bf4346;
        border-color: #b93f42;
    }

.btn-orange {
    color: #fff;
    background-color: #f46f34;
    border-color: #f46f34;
}

    .btn-orange:hover, .btn-orange:focus, .btn-orange:active, .btn-orange.active, .open .dropdown-toggle.btn-orange {
        color: #fff;
        background-color: #d65116;
        border-color: #ba4713;
    }

    .btn-orange:active, .btn-orange.active, .open .dropdown-toggle.btn-orange {
        background-image: none;
    }

    .btn-orange.disabled, .btn-orange[disabled], fieldset[disabled] .btn-orange, .btn-orange.disabled:hover, .btn-orange[disabled]:hover, fieldset[disabled] .btn-orange:hover, .btn-orange.disabled:focus, .btn-orange[disabled]:focus, fieldset[disabled] .btn-orange:focus, .btn-orange.disabled:active, .btn-orange[disabled]:active, fieldset[disabled] .btn-orange:active, .btn-orange.disabled.active, .btn-orange[disabled].active, fieldset[disabled] .btn-orange.active {
        background-color: #e9662c;
        border-color: #e85f23;
    }

.btn-green {
    color: #fff;
    background-color: #488c6c;
    border-color: #458567;
}

    .btn-green:hover, .btn-green:focus, .btn-green:active, .btn-green.active, .open .dropdown-toggle.btn-green {
        color: #fff;
        background-color: #3a7157;
        border-color: #305d48;
    }

    .btn-green:active, .btn-green.active, .open .dropdown-toggle.btn-green {
        background-image: none;
    }

    .btn-green.disabled, .btn-green[disabled], fieldset[disabled] .btn-green, .btn-green.disabled:hover, .btn-green[disabled]:hover, fieldset[disabled] .btn-green:hover, .btn-green.disabled:focus, .btn-green[disabled]:focus, fieldset[disabled] .btn-green:focus, .btn-green.disabled:active, .btn-green[disabled]:active, fieldset[disabled] .btn-green:active, .btn-green.disabled.active, .btn-green[disabled].active, fieldset[disabled] .btn-green.active {
        background-color: #488c6c;
        border-color: #458567;
    }

.btn-yellow {
    color: #fff;
    background-color: #f2994b;
    border-color: #f19441;
}

    .btn-yellow:hover, .btn-yellow:focus, .btn-yellow:active, .btn-yellow.active, .open .dropdown-toggle.btn-yellow {
        color: #fff;
        background-color: #ef8325;
        border-color: #e57411;
    }

    .btn-yellow:active, .btn-yellow.active, .open .dropdown-toggle.btn-yellow {
        background-image: none;
    }

    .btn-yellow.disabled, .btn-yellow[disabled], fieldset[disabled] .btn-yellow, .btn-yellow.disabled:hover, .btn-yellow[disabled]:hover, fieldset[disabled] .btn-yellow:hover, .btn-yellow.disabled:focus, .btn-yellow[disabled]:focus, fieldset[disabled] .btn-yellow:focus, .btn-yellow.disabled:active, .btn-yellow[disabled]:active, fieldset[disabled] .btn-yellow:active, .btn-yellow.disabled.active, .btn-yellow[disabled].active, fieldset[disabled] .btn-yellow.active {
        background-color: #f2994b;
        border-color: #f19441;
    }

.btn-blue {
    color: #fff;
    background-color: #0a819c;
    border-color: #097992;
}

    .btn-blue:hover, .btn-blue:focus, .btn-blue:active, .btn-blue.active, .open .dropdown-toggle.btn-blue {
        color: #fff;
        background-color: #086176;
        border-color: #064a59;
    }

    .btn-blue:active, .btn-blue.active, .open .dropdown-toggle.btn-blue {
        background-image: none;
    }

    .btn-blue.disabled, .btn-blue[disabled], fieldset[disabled] .btn-blue, .btn-blue.disabled:hover, .btn-blue[disabled]:hover, fieldset[disabled] .btn-blue:hover, .btn-blue.disabled:focus, .btn-blue[disabled]:focus, fieldset[disabled] .btn-blue:focus, .btn-blue.disabled:active, .btn-blue[disabled]:active, fieldset[disabled] .btn-blue:active, .btn-blue.disabled.active, .btn-blue[disabled].active, fieldset[disabled] .btn-blue.active {
        background-color: #0a819c;
        border-color: #097992;
    }

.btn-violet {
    color: #fff;
    background-color: #9351ad;
    border-color: #8d4ea6;
}

    .btn-violet:hover, .btn-violet:focus, .btn-violet:active, .btn-violet.active, .open .dropdown-toggle.btn-violet {
        color: #fff;
        background-color: #7b4491;
        border-color: #6a3a7c;
    }

    .btn-violet:active, .btn-violet.active, .open .dropdown-toggle.btn-violet {
        background-image: none;
    }

    .btn-violet.disabled, .btn-violet[disabled], fieldset[disabled] .btn-violet, .btn-violet.disabled:hover, .btn-violet[disabled]:hover, fieldset[disabled] .btn-violet:hover, .btn-violet.disabled:focus, .btn-violet[disabled]:focus, fieldset[disabled] .btn-violet:focus, .btn-violet.disabled:active, .btn-violet[disabled]:active, fieldset[disabled] .btn-violet:active, .btn-violet.disabled.active, .btn-violet[disabled].active, fieldset[disabled] .btn-violet.active {
        background-color: #9351ad;
        border-color: #8d4ea6;
    }

.btn-pink {
    color: #fff;
    background-color: #bf3773;
    border-color: #b7356e;
}

    .btn-pink:hover, .btn-pink:focus, .btn-pink:active, .btn-pink.active, .open .dropdown-toggle.btn-pink {
        color: #fff;
        background-color: #9f2e60;
        border-color: #882752;
    }

    .btn-pink:active, .btn-pink.active, .open .dropdown-toggle.btn-pink {
        background-image: none;
    }

    .btn-pink.disabled, .btn-pink[disabled], fieldset[disabled] .btn-pink, .btn-pink.disabled:hover, .btn-pink[disabled]:hover, fieldset[disabled] .btn-pink:hover, .btn-pink.disabled:focus, .btn-pink[disabled]:focus, fieldset[disabled] .btn-pink:focus, .btn-pink.disabled:active, .btn-pink[disabled]:active, fieldset[disabled] .btn-pink:active, .btn-pink.disabled.active, .btn-pink[disabled].active, fieldset[disabled] .btn-pink.active {
        background-color: #bf3773;
        border-color: #b7356e;
    }

.btn-grey {
    color: #fff;
    background-color: #1b4283;
    border-color: #475861;
}

    .btn-grey:hover, .btn-grey:focus, .btn-grey:active, .btn-grey.active, .open .dropdown-toggle.btn-grey {
        color: #fff;
        background-color: #3a484f;
        border-color: #2d383e;
    }

    .btn-grey:active, .btn-grey.active, .open .dropdown-toggle.btn-grey {
        background-image: none;
    }

    .btn-grey.disabled, .btn-grey[disabled], fieldset[disabled] .btn-grey, .btn-grey.disabled:hover, .btn-grey[disabled]:hover, fieldset[disabled] .btn-grey:hover, .btn-grey.disabled:focus, .btn-grey[disabled]:focus, fieldset[disabled] .btn-grey:focus, .btn-grey.disabled:active, .btn-grey[disabled]:active, fieldset[disabled] .btn-grey:active, .btn-grey.disabled.active, .btn-grey[disabled].active, fieldset[disabled] .btn-grey.active {
        background-color: #1b4283;
        border-color: #475861;
    }

.btn-dark {
    color: #fff;
    background-color: #594857;
    border-color: #534351;
}

    .btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active, .open .dropdown-toggle.btn-dark {
        color: #fff;
        background-color: #423641;
        border-color: #322830;
    }

    .btn-dark:active, .btn-dark.active, .open .dropdown-toggle.btn-dark {
        background-image: none;
    }

    .btn-dark.disabled, .btn-dark[disabled], fieldset[disabled] .btn-dark, .btn-dark.disabled:hover, .btn-dark[disabled]:hover, fieldset[disabled] .btn-dark:hover, .btn-dark.disabled:focus, .btn-dark[disabled]:focus, fieldset[disabled] .btn-dark:focus, .btn-dark.disabled:active, .btn-dark[disabled]:active, fieldset[disabled] .btn-dark:active, .btn-dark.disabled.active, .btn-dark[disabled].active, fieldset[disabled] .btn-dark.active {
        background-color: #594857;
        border-color: #534351;
    }

.btn-white {
    color: #999;
    background-color: #fff;
    border-color: #e5e5e5;
}

    .btn-white:hover, .btn-white:focus, .btn-white:active, .btn-white.active, .open .dropdown-toggle.btn-white {
        color: #999;
        background-color: #ebebeb;
        border-color: #c6c6c6;
    }

    .btn-white:active, .btn-white.active, .open .dropdown-toggle.btn-white {
        background-image: none;
    }

    .btn-white.disabled, .btn-white[disabled], fieldset[disabled] .btn-white, .btn-white.disabled:hover, .btn-white[disabled]:hover, fieldset[disabled] .btn-white:hover, .btn-white.disabled:focus, .btn-white[disabled]:focus, fieldset[disabled] .btn-white:focus, .btn-white.disabled:active, .btn-white[disabled]:active, fieldset[disabled] .btn-white:active, .btn-white.disabled.active, .btn-white[disabled].active, fieldset[disabled] .btn-white.active {
        background-color: #fff;
        border-color: #e5e5e5;
    }

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.btn-group > .btn-group:last-child > .btn:first-child {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.btn-group > .btn-group:first-child > .btn:last-child, .btn-group > .btn-group:first-child > .dropdown-toggle {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
    border-top-right-radius: 7px !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
    border-bottom-left-radius: 7px !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.input-group .input-group-addon {
    color: #999;
    border-color: #e5e5e5;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.input-group .input-group-btn .btn {
    border: 1px solid #e5e5e5 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

    .input-group .input-group-btn .btn.btn-primary {
        border-color: #086d84 !important;
    }

    .input-group .input-group-btn .btn.btn-success {
        border-color: #4cae4c !important;
    }

    .input-group .input-group-btn .btn.btn-warning {
        border-color: #eea236 !important;
    }

    .input-group .input-group-btn .btn.btn-info {
        border-color: #46b8da !important;
    }

    .input-group .input-group-btn .btn.btn-danger {
        border-color: #d43f3a !important;
    }

    .input-group .input-group-btn .btn.btn-red {
        border-color: #ad3b3e !important;
    }

    .input-group .input-group-btn .btn.btn-orange {
        border-color: #e45618 !important;
    }

    .input-group .input-group-btn .btn.btn-green {
        border-color: #3f7b5f !important;
    }

    .input-group .input-group-btn .btn.btn-yellow {
        border-color: #f08c33 !important;
    }

    .input-group .input-group-btn .btn.btn-blue {
        border-color: #086d84 !important;
    }

    .input-group .input-group-btn .btn.btn-pink {
        border-color: #ab3167 !important;
    }

    .input-group .input-group-btn .btn.btn-violet {
        border-color: #84499c !important;
    }

    .input-group .input-group-btn .btn.btn-grey {
        border-color: #405058 !important;
    }

    .input-group .input-group-btn .btn.btn-dark {
        border-color: #4b3d49 !important;
    }

.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

    .input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }

    .input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }

        .input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
            border-bottom-right-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }

.input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.input-group-btn:last-child > .btn {
    height: 34px;
}

.dropdown-menu {
    /*  margin:0;
    padding:0;
    border:0;
    border-radius:0;
    background:#1f1f1f;
    -webkit-box-shadow:0 1px 1px rgba(0, 0, 0, 0.15) !important;
    box-shadow:0 1px 1px rgba(0, 0, 0, 0.15) !important;*/
}

    .dropdown-menu li a {
        color: #f46f34;
        padding: 9px 10px;
        font-size: 12px;
    }

.dropdown-submenu {
    position: relative;
}

    .dropdown-submenu > .dropdown-menu {
        top: 5px;
        left: 100%;
        margin-top: -6px;
        margin-left: -1px;
    }

    .dropdown-submenu > a:after {
        position: absolute;
        display: inline-block;
        right: 7px;
        top: 7px;
        font-family: FontAwesome;
        height: auto;
        content: "\f105";
        font-weight: 300;
    }

    .dropdown-submenu:hover > .dropdown-menu {
        display: block;
    }

    .dropdown-submenu.pull-left {
        float: none;
    }

        .dropdown-submenu.pull-left > .dropdown-menu {
            left: -100%;
            margin-left: 10px;
        }

.dropup .dropdown-submenu > .dropdown-menu {
    top: auto;
    bottom: 0;
    margin-top: 0;
    margin-bottom: -2px;
}

[class^="fa-"], [class^="glyphicon-"], [class^="icon-"], [class*=" fa-"], [class*=" glyphicon-"], [class*=" icon-"] {
    display: inline-block;
    margin-top: 1px;
    font-size: 14px;
    margin-right: 0.3em;
    line-height: 14px;
    -webkit-font-smoothing: antialiased;
}

.adlvsmds-score-indicator {
    font-size: 10px;
    padding-right: 2px;
}

li [class^="fa-"], li [class^="glyphicon-"], li [class^="icon-"], li [class*=" fa-"], li [class*=" glyphicon-"], li [class*=" icon-"] {
    display: inline-block;
    width: 1.25em;
    text-align: center;
}

li [class^="glyphicon-"], li [class*=" glyphicon-"] {
    top: 2px;
}

li [class^="icon-"], li [class*=" icon-"] {
    top: 1px;
    position: relative;
}

    li [class^="fa-"].icon-large, li [class^="glyphicon-"].icon-large, li [class^="icon-"].icon-large, li [class*=" fa-"].icon-large, li [class*=" glyphicon-"].icon-large, li [class*=" icon-"].icon-large {
        width: 1.5625em;
    }

.fa-2x {
    font-size: 2em !important;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
    background: #f9f9f9;
    font-size: 12px;
}

.datetime-picker i {
    cursor: pointer;
}

.custom-combobox {
    position: relative;
    display: block;
    height: 30px;
}

.custom-combobox-toggle {
    color: #555;
    display: block;
    position: absolute;
    right: 1px;
    top: 1px;
    height: 28px;
    z-index: 2;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
}

.custom-combobox .form-control {
    margin: 0;
    padding: 5px 10px;
    height: 30px;
}

.ui-autocomplete.ui-corner-all {
    max-height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
}

.ui-autocomplete .ui-menu-item a {
    border: 0 !important;
}

    .ui-autocomplete .ui-menu-item a.ui-state-focus {
        background: none !important;
        border: 0 !important;
        border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
    }

    .ui-autocomplete .ui-menu-item a:hover {
        background: #3399ff !important;
        color: #fff !important;
        border: 0 !important;
        border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
    }

.nav-pills li.active a {
    background: #0a819c;
}

    .nav-pills li.active a:hover, .nav-pills li.active a:focus {
        background: #0a819c;
    }

.nav-pills li a {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.list-group .list-group-item-action {
    border-color: #e5e5e5;
    color: #999;
    width: 100%;
}

    .list-group .list-group-item-action.active {
        background: #0a819c;
        border-color: #0a819c;
    }

        .list-group .list-group-item-action.active:hover, .list-group .list-group-item-action.active:focus {
            background: #0a819c;
            border-color: #0a819c;
        }

    .list-group .list-group-item-action:first-child {
        border-top-right-radius: 5px !important;
        border-top-left-radius: 5px !important;
    }

    .list-group .list-group-item-action:last-child {
        border-bottom-right-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
    }

.nav-tabs {
    border-color: #e5e5e5 !important;
}

    .nav-tabs li a {
        -webkit-border-radius: 7px 7px 0 0 !important;
        -moz-border-radius: 7px 7px 0 0 !important;
        border-radius: 7px 7px 0 0 !important;
    }

.tab-content>.active {
    display: flex;
}

.tab-content>.active>.ng-star-inserted {
    width: 100%
}

.tab-content {
    background: #fff;
    padding: 20px 15px;
    margin-bottom: 20px;
    border: 1px solid;
    border-color: #e5e5e5 !important;
    border-top: 0;
}

.tabbable:before {
    display: table;
    line-height: 0;
    content: "";
}

.tabbable:after {
    display: table;
    line-height: 0;
    content: "";
    clear: both;
}

.tabbable.tabs-left .nav-tabs {
    float: left;
    display: inline-block;
    border-bottom: 0;
}

    .tabbable.tabs-left .nav-tabs > li {
        float: none;
    }

        .tabbable.tabs-left .nav-tabs > li.active > a, .tabbable.tabs-left .nav-tabs > li:hover > a, .tabbable.tabs-left .nav-tabs > li:focus > a {
            border: 1px solid;
            border-color: #e5e5e5 !important;
            border-right: 0;
        }

        .tabbable.tabs-left .nav-tabs > li > a {
            border-right: 0;
            min-width: 74px;
            margin-bottom: 3px;
            margin-right: -1px;
            -webkit-border-radius: 7px 0 0 7px !important;
            -moz-border-radius: 7px 0 0 7px !important;
            border-radius: 7px 0 0 7px !important;
        }

.tabbable.tabs-left .tab-content {
    overflow: auto;
    border: 1px solid;
    border-color: #e5e5e5 !important;
}

.tabbable.tabs-right .nav-tabs {
    float: right;
    display: inline-block;
    border-bottom: 0;
}

    .tabbable.tabs-right .nav-tabs > li {
        float: none;
    }

        .tabbable.tabs-right .nav-tabs > li.active > a, .tabbable.tabs-right .nav-tabs > li:hover > a, .tabbable.tabs-right .nav-tabs > li:focus > a {
            border: 1px solid;
            border-color: #e5e5e5 !important;
            border-left: 0;
        }

        .tabbable.tabs-right .nav-tabs > li > a {
            border-left: 0;
            min-width: 74px;
            margin-bottom: 3px;
            margin-left: -1px;
            -webkit-border-radius: 0 7px 7px 0 !important;
            -moz-border-radius: 0 7px 7px 0 !important;
            border-radius: 0 7px 7px 0 !important;
        }

.tabbable.tabs-right .tab-content {
    overflow: auto;
    border: 1px solid;
    border-color: #e5e5e5 !important;
}

.tabbable.tabs-below .nav-tabs {
    margin-bottom: 20px !important;
    border: 0;
}

    .tabbable.tabs-below .nav-tabs > li {
        margin-top: -1px;
        margin-bottom: 0;
    }

        .tabbable.tabs-below .nav-tabs > li.active > a {
            border: 1px solid;
            border-color: #e5e5e5 !important;
            border-top: 0;
        }

        .tabbable.tabs-below .nav-tabs > li > a {
            -webkit-border-radius: 0 0 7px 7px !important;
            -moz-border-radius: 0 0 7px 7px !important;
            border-radius: 0 0 7px 7px !important;
        }

.tabbable.tabs-below .tab-content {
    overflow: auto;
    margin-bottom: 0 !important;
    border: 1px solid;
    border-color: #e5e5e5 !important;
}

.bg-primary {
    background-color: #37BC9B;
    color: #fff;
}

.bg-success {
    background-color: #8CC152;
    color: #fff;
}

.bg-danger {
    background-color: #E9573F;
    color: #fff;
}

.bg-warning {
    background-color: #F6BB42;
    color: #fff;
}

.bg-info {
    background-color: #3BAFDA;
    color: #fff;
}

.bg-orange {
    background-color: #f66c2f;
    color: #fff;
}

.bg-green {
    background-color: #459f75;
    color: #fff;
}

.bg-yellow {
    background-color: #f6a35a;
    color: #fff;
}

.bg-blue {
    background-color: #0074bd;
    color: #fff;
}

.bg-violet {
    background-color: #9e51bc;
    color: #fff;
}

.bg-pink {
    background-color: #ce417f;
    color: #fff;
}

.bg-grey {
    background-color: #717171;
    color: #fff;
}

.bg-dark {
    background-color: #434A54;
    color: #fff;
}



.opioid-bg-danger {
    background-color: #f2dede !important;
}

.opioid-bg-warning {
    background-color: #fff3cd !important;
}

.opioid-bg-success {
    background-color: #f8f9fa !important;
}

.progress {
    position: relative;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

    .progress.progress-xs {
        height: 5px;
        margin: 20px -15px;
    }

    .progress.progress-sm {
        height: 11px;
        margin-top: 5px;
    }

    .progress.progress-lg {
        height: 25px;
    }

    .progress .progress-bar {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        background-color: #f0ad4e;
    }

        .progress .progress-bar.progress-bar-success {
            background-color: #7ab03e !important;
        }

        .progress .progress-bar.progress-bar-warning {
            background-color: #f0ad4e !important;
        }

        .progress .progress-bar.progress-bar-info {
            background-color: #32a0c9 !important;
        }

        .progress .progress-bar.progress-bar-danger {
            background-color: #da4a32 !important;
        }

        .progress .progress-bar.progress-bar-red {
            background-color: #bf4346 !important;
        }

        .progress .progress-bar.progress-bar-orange {
            background-color: #e9662c !important;
        }

        .progress .progress-bar.progress-bar-green {
            background-color: #488c6c !important;
        }

        .progress .progress-bar.progress-bar-yellow {
            background-color: #f2994b !important;
        }

        .progress .progress-bar.progress-bar-blue {
            background-color: #0a819c !important;
        }

        .progress .progress-bar.progress-bar-violet {
            background-color: #9351ad !important;
        }

        .progress .progress-bar.progress-bar-pink {
            background-color: #bf3773 !important;
        }

        .progress .progress-bar.progress-bar-grey {
            background-color: #5d5d5d !important;
        }

        .progress .progress-bar.progress-bar-dark {
            background-color: #33383f !important;
        }

        .progress .progress-bar.progress-bar-white {
            background-color: #fff !important;
        }

        .progress .progress-bar.six-sec-ease-in-out {
            -webkit-transition: width 6s ease-in-out;
            -moz-transition: width 6s ease-in-out;
            -ms-transition: width 6s ease-in-out;
            -o-transition: width 6s ease-in-out;
            transition: width 6s ease-in-out;
        }

    .progress.wide {
        width: 60px;
        height: 150px;
    }

    .progress.vertical.progress-xs {
        width: 10px;
        margin-top: 0;
    }

    .progress.vertical.progress-sm {
        width: 20px;
        margin-top: 0;
    }

    .progress.vertical.progress-lg {
        width: 70px;
    }

.form-actions {
    padding: 20px 0;
    background: #fafafa;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

    .form-actions:before, .form-actions:after {
        display: table;
        line-height: 0;
        content: "";
    }

    .form-actions:after {
        clear: both;
    }

    .form-actions.top {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .form-actions.none-bg {
        background: transparent;
        border-top: 1px dashed #f3f3f3;
    }

        .form-actions.none-bg.top {
            border-bottom: 1px dashed #f3f3f3;
            border-top: 0;
        }

.state-success .form-control {
    border-color: #7edc7f !important;
}

.state-warning .form-control {
    border-color: #dcb359 !important;
}

.state-error .form-control {
    border-color: #db4c4a !important;
}

.state-success em {
    color: #7edc7f !important;
    margin-top: 5px;
    display: block;
}

.state-warning em {
    color: #dcb359 !important;
    margin-top: 5px;
    display: block;
}

.state-error em {
    color: #db4c4a !important;
    margin-top: 5px;
    display: block;
}

.state-success input, .state-success select, .state-success textarea {
    background: #dff0d8 !important;
}

.state-warning input, .state-warning select, .state-warning textarea {
    background: #fcf8e3 !important;
}

.state-error input, .state-error select, .state-error textarea {
    background: #f2dede !important;
}

.form-bordered .control-label {
    padding-top: 16px;
}

.form-bordered .mb-3 {
    margin: 0;
    border-bottom: 1px solid #f7f7f7;
}

    .form-bordered .mb-3 > div {
        padding: 15px;
        border-left: 1px solid #f7f7f7;
    }

    .form-bordered .mb-3:last-child {
        border-bottom: 0;
    }

.form-bordered .help-block {
    margin-bottom: 0px;
}

.form-bordered.dashed .mb-3 {
    border-bottom: 1px dashed #f3f3f3;
}

    .form-bordered.dashed .mb-3 > div {
        border-left: 1px dashed #f3f3f3;
    }

    .form-bordered.dashed .mb-3:last-child {
        border-bottom: 0;
    }

.form-seperated .control-label {
    padding-top: 16px;
}

.form-seperated .mb-3 {
    margin: 0;
    border-bottom: 1px solid #f7f7f7;
}

    .form-seperated .mb-3 > div {
        padding: 15px;
    }

    .form-seperated .mb-3:last-child {
        border-bottom: 0;
    }

.form-seperated .help-block {
    margin-bottom: 0px;
}

.form-seperated.dashed .mb-3 {
    border-bottom: 1px dashed #f3f3f3;
}

    .form-seperated.dashed .mb-3:last-child {
        border-bottom: 0;
    }

.form-horizontal.form-row-stripped .mb-3:nth-child(odd) {
    background: #fcfcfc;
}

.form-horizontal.form-seperated .radio, .form-horizontal.form-seperated .checkbox, .form-horizontal.form-seperated .radio-inline, .form-horizontal.form-seperated .checkbox-inline {
    padding-top: 0;
}

.text-primary {
    color: #0a819c !important;
}

.text-red {
    color: #bf4346 !important;
}

.text-orange {
    color: #e9662c !important;
}

.text-green {
    color: #488c6c !important;
}

.text-yellow {
    color: #f2994b !important;
}

.text-blue {
    color: #0a819c !important;
}

.text-pink {
    color: #bf3773 !important;
}

.text-violet {
    color: #9351ad !important;
}

.text-grey {
    color: #1b4283 !important;
}

.text-dark {
    color: #594857 !important;
}

.text-white {
    color: #fff !important;
}

.text-facebook {
    color: #418bca;
}

.text-twitter {
    color: #5bc0de;
}

.text-google-plus {
    color: #dd4c39;
}

.text-dribbble {
    color: #ec5d92;
}

.input-mini {
    width: 45px !important;
}

.input-xsmall {
    width: 80px !important;
}

.input-small {
    width: 120px !important;
}

.input-medium {
    width: 240px !important;
}

.input-large {
    width: 320px !important;
}

.input-xlarge {
    width: 480px !important;
}

.input-inline {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

.mb-3 .input-inline {
    margin-right: 5px;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
    font-size: 13px;
}

.ui-spinner-input {
    height: 24px;
}

.modal-dialog {
    margin: 0;
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%) !important;
}

.modal .modal-dialog .modal-content {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
    min-height: 500px !important;
}

    .modal .modal-dialog .modal-content .modal-header.modal-header-primary {
        background: #0a819c;
    }

        .modal .modal-dialog .modal-content .modal-header.modal-header-primary .modal-title {
            color: #fff;
        }

    .modal .modal-dialog .modal-content .modal-footer.modal-footer-left {
        text-align: left !important;
    }

.modal .modal-body {
    height: 100%;
    min-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-title {
    color: #000000;
    font-size: 25px !important;
}

.modal .text-content {
    text-align: justify;
    font-family: 'Open Sans', sans-serif;
}

.portlet {
    clear: both;
    margin-top: 0px;
    margin-bottom: 25px;
    padding: 0px;
}

.portlet {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

    .portlet.box > .portlet-header {
        margin-bottom: 0;
        padding: 8px 15px;
        border-top-right-radius: 5px !important;
        border-top-left-radius: 5px !important;
    }

    .portlet > .portlet-header {
        margin-bottom: 15px;
        background: #fff;
        border-bottom: 1px solid #e5e5e5;
    }

        .portlet > .portlet-header:before {
            content: "";
            display: table;
            line-height: 0;
        }

        .portlet > .portlet-header:after {
            content: "";
            display: table;
            line-height: 0;
            clear: both;
        }

        .portlet > .portlet-header .caption {
            float: left;
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            padding: 0;
        }

            .portlet > .portlet-header .caption i {
                float: left;
                margin-top: 4px;
                display: inline-block !important;
                font-size: 13px;
                margin-right: 5px;
                color: #999;
            }

        .portlet > .portlet-header .actions {
            display: inline-block;
            padding: 0;
            margin: 0;
            margin-top: 6px;
            float: right;
        }

            .portlet > .portlet-header .actions > .btn {
                padding: 4px 10px;
                margin-top: -18px;
            }

                .portlet > .portlet-header .actions > .btn.btn-sm {
                    padding: 3px 8px;
                    margin-top: -13px;
                }

                .portlet > .portlet-header .actions > .btn.btn-xs {
                    padding: 1px 5px;
                    margin-top: -13px;
                }

            .portlet > .portlet-header .actions > .btn-group {
                margin-top: -12px;
            }

                .portlet > .portlet-header .actions > .btn-group > .btn {
                    padding: 4px 10px;
                    margin-top: -1px;
                }

                    .portlet > .portlet-header .actions > .btn-group > .btn.btn-sm {
                        padding: 3px 8px;
                        margin-top: -1px;
                    }

                    .portlet > .portlet-header .actions > .btn-group > .btn.btn-xs {
                        padding: 1px 5px;
                        margin-top: -1px;
                    }

        .portlet > .portlet-header .pagination.pagination-sm {
            float: right;
            display: inline-block;
            margin: 0px;
        }

            .portlet > .portlet-header .pagination.pagination-sm li a {
                padding: 3px 10px;
            }

        .portlet > .portlet-header .tools {
            display: inline-block;
            padding: 0;
            margin: 0;
            float: right;
        }

            .portlet > .portlet-header .tools i {
                margin-left: 5px;
                cursor: pointer;
            }

    .portlet .portlet-body {
        background: #fff;
        padding: 15px;
        clear: both;
        border-bottom-right-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
    }

        .portlet .portlet-body.form {
            padding: 0 !important;
        }

    .portlet.portlet-default > .portlet-header {
        background: #fff;
        color: #999;
        border-bottom: 1px solid #f0f2f6;
    }

        .portlet.portlet-default > .portlet-header i {
            color: #999;
        }

        .portlet.portlet-default > .portlet-header .btn i {
            color: #999;
        }

    .portlet.portlet-primary {
        border-color: #0a819c;
    }

        .portlet.portlet-primary > .portlet-header {
            background: #0a819c;
            color: #fff;
        }

    .portlet.portlet-red {
        border-color: #bf4346;
    }

        .portlet.portlet-red > .portlet-header {
            background: #bf4346;
            color: #fff;
        }

    .portlet.portlet-orange {
        border-color: #e9662c;
    }

        .portlet.portlet-orange > .portlet-header {
            background: #e9662c;
            color: #fff;
        }

    .portlet.portlet-green {
        border-color: #488c6c;
    }

        .portlet.portlet-green > .portlet-header {
            background: #488c6c;
            color: #fff;
        }

    .portlet.portlet-yellow {
        border-color: #f2994b;
    }

        .portlet.portlet-yellow > .portlet-header {
            background: #f2994b;
            color: #fff;
        }

    .portlet.portlet-blue {
        border-color: #0a819c;
    }

        .portlet.portlet-blue > .portlet-header {
            background: #0a819c;
            color: #fff;
        }

    .portlet.portlet-violet {
        border-color: #9351ad;
    }

        .portlet.portlet-violet > .portlet-header {
            background: #9351ad;
            color: #fff;
        }

    .portlet.portlet-pink {
        border-color: #bf3773;
    }

        .portlet.portlet-pink > .portlet-header {
            background: #bf3773;
            color: #fff;
        }

    .portlet.portlet-grey {
        border-color: #1b4283;
    }

        .portlet.portlet-grey > .portlet-header {
            background: #1b4283;
            color: #fff;
        }

    .portlet.portlet-dark {
        border-color: #594857;
    }

        .portlet.portlet-dark > .portlet-header {
            background: #594857;
            color: #fff;
        }

.portlet {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

    .portlet.color {
        padding: 0;
    }

        .portlet.color .portlet-header {
            margin-bottom: 0;
            border: 0px;
        }

            .portlet.color .portlet-header .btn.btn-white i {
                color: #999;
            }

        .portlet.color .portlet-body {
            background: transparent;
        }

        .portlet.color.portlet-default {
            background: #fff !important;
        }

            .portlet.color.portlet-default > .portlet-header {
                color: #999;
                border: 0;
            }

                .portlet.color.portlet-default > .portlet-header > .caption i {
                    color: #999;
                    border: 0;
                }

                .portlet.color.portlet-default > .portlet-header > .tools {
                    border: 0px;
                }

            .portlet.color.portlet-default > .portlet-body {
                color: #999;
                border: 0;
                padding: 0;
                background: transparent;
            }

        .portlet.color.portlet-primary {
            background: #0a819c !important;
        }

            .portlet.color.portlet-primary > .portlet-header {
                color: #fff;
                border: 0;
            }

                .portlet.color.portlet-primary > .portlet-header > .caption i {
                    color: #fff;
                    border: 0;
                }

                .portlet.color.portlet-primary > .portlet-header > .tools {
                    border: 0px;
                }

            .portlet.color.portlet-primary > .portlet-body {
                color: #fff;
                border: 0;
            }

        .portlet.color > .portlet-body.brand-primary {
            background: #0a819c !important;
        }

        .portlet.color.portlet-red {
            background: #bf4346 !important;
        }

            .portlet.color.portlet-red > .portlet-header {
                color: #fff;
                border: 0;
            }

                .portlet.color.portlet-red > .portlet-header > .caption i {
                    color: #fff;
                    border: 0;
                }

                .portlet.color.portlet-red > .portlet-header > .tools {
                    border: 0px;
                }

            .portlet.color.portlet-red > .portlet-body {
                color: #fff;
                border: 0;
            }

        .portlet.color > .portlet-body.color-red {
            background: #bf4346 !important;
        }

        .portlet.color.portlet-orange {
            background: #e9662c !important;
        }

            .portlet.color.portlet-orange > .portlet-header {
                color: #fff;
                border: 0;
            }

                .portlet.color.portlet-orange > .portlet-header > .caption i {
                    color: #fff;
                    border: 0;
                }

                .portlet.color.portlet-orange > .portlet-header > .tools {
                    border: 0px;
                }

            .portlet.color.portlet-orange > .portlet-body {
                color: #fff;
                border: 0;
            }

        .portlet.color > .portlet-body.color-orange {
            background: #e9662c !important;
        }

        .portlet.color.portlet-green {
            background: #488c6c !important;
        }

            .portlet.color.portlet-green > .portlet-header {
                color: #fff;
                border: 0;
            }

                .portlet.color.portlet-green > .portlet-header > .caption i {
                    color: #fff;
                    border: 0;
                }

                .portlet.color.portlet-green > .portlet-header > .tools {
                    border: 0px;
                }

            .portlet.color.portlet-green > .portlet-body {
                color: #fff;
                border: 0;
            }

        .portlet.color > .portlet-body.color-green {
            background: #488c6c !important;
        }

        .portlet.color.portlet-yellow {
            background: #f2994b !important;
        }

            .portlet.color.portlet-yellow > .portlet-header {
                color: #fff;
                border: 0;
            }

                .portlet.color.portlet-yellow > .portlet-header > .caption i {
                    color: #fff;
                    border: 0;
                }

                .portlet.color.portlet-yellow > .portlet-header > .tools {
                    border: 0px;
                }

            .portlet.color.portlet-yellow > .portlet-body {
                color: #fff;
                border: 0;
            }

        .portlet.color > .portlet-body.color-yellow {
            background: #f2994b !important;
        }

        .portlet.color.portlet-blue {
            background: #0a819c !important;
        }

            .portlet.color.portlet-blue > .portlet-header {
                color: #fff;
                border: 0;
            }

                .portlet.color.portlet-blue > .portlet-header > .caption i {
                    color: #fff;
                    border: 0;
                }

                .portlet.color.portlet-blue > .portlet-header > .tools {
                    border: 0px;
                }

            .portlet.color.portlet-blue > .portlet-body {
                color: #fff;
                border: 0;
            }

        .portlet.color > .portlet-body.color-blue {
            background: #0a819c !important;
        }

        .portlet.color.portlet-violet {
            background: #9351ad !important;
        }

            .portlet.color.portlet-violet > .portlet-header {
                color: #fff;
                border: 0;
            }

                .portlet.color.portlet-violet > .portlet-header > .caption i {
                    color: #fff;
                    border: 0;
                }

                .portlet.color.portlet-violet > .portlet-header > .tools {
                    border: 0px;
                }

            .portlet.color.portlet-violet > .portlet-body {
                color: #fff;
                border: 0;
            }

        .portlet.color > .portlet-body.color-violet {
            background: #9351ad !important;
        }

        .portlet.color.portlet-pink {
            background: #bf3773 !important;
        }

            .portlet.color.portlet-pink > .portlet-header {
                color: #fff;
                border: 0;
            }

                .portlet.color.portlet-pink > .portlet-header > .caption i {
                    color: #fff;
                    border: 0;
                }

                .portlet.color.portlet-pink > .portlet-header > .tools {
                    border: 0px;
                }

            .portlet.color.portlet-pink > .portlet-body {
                color: #fff;
                border: 0;
            }

        .portlet.color > .portlet-body.color-pink {
            background: #bf3773 !important;
        }

        .portlet.color.portlet-grey {
            background: #1b4283 !important;
        }

            .portlet.color.portlet-grey > .portlet-header {
                color: #fff;
                border: 0;
            }

                .portlet.color.portlet-grey > .portlet-header > .caption i {
                    color: #fff;
                    border: 0;
                }

                .portlet.color.portlet-grey > .portlet-header > .tools {
                    border: 0px;
                }

            .portlet.color.portlet-grey > .portlet-body {
                color: #fff;
                border: 0;
            }

        .portlet.color > .portlet-body.color-grey {
            background: #1b4283 !important;
        }

        .portlet.color.portlet-dark {
            background: #594857 !important;
        }

            .portlet.color.portlet-dark > .portlet-header {
                color: #fff;
                border: 0;
            }

                .portlet.color.portlet-dark > .portlet-header > .caption i {
                    color: #fff;
                    border: 0;
                }

                .portlet.color.portlet-dark > .portlet-header > .tools {
                    border: 0px;
                }

            .portlet.color.portlet-dark > .portlet-body {
                color: #fff;
                border: 0;
            }

        .portlet.color > .portlet-body.color-dark {
            background: #594857 !important;
        }

.portlet-tabs > .nav-tabs {
    position: relative;
    top: -44px;
    margin-right: 15px;
    border-bottom: none;
    padding: 4px 0px;
    overflow: hidden;
}

    .portlet-tabs > .nav-tabs > li {
        float: right;
        margin-left: 1px;
    }

        .portlet-tabs > .nav-tabs > li:last-child a {
            border-right: 0;
        }

        .portlet-tabs > .nav-tabs > li.active {
            color: #0a819c;
            border-top-color: transparent;
        }

            .portlet-tabs > .nav-tabs > li.active a {
                margin-bottom: 0px;
                border-bottom: 0;
                margin-left: 0px;
                margin-right: 0px;
                border-left: 0;
                border-right: 0;
                background-color: none !important;
                border-top-color: transparent !important;
                color: #999;
                cursor: default;
            }

                .portlet-tabs > .nav-tabs > li.active a:hover {
                    background-color: #fff !important;
                }

        .portlet-tabs > .nav-tabs > li a {
            color: #fff;
            padding-top: 8px;
            padding-bottom: 10px;
            line-height: 16px;
            margin-top: 6px;
            margin-left: 0px;
            margin-right: 0px;
            border-left: 0;
            border-right: 0;
        }

            .portlet-tabs > .nav-tabs > li a:hover {
                color: #999;
                margin-bottom: 0;
                border-bottom-color: transparent;
                margin-left: 0;
                margin-right: 0;
                border-left: 0;
                border-right: 0;
                background-color: none !important;
                border-top-color: transparent;
            }

.portlet-tabs > .tab-content {
    padding: 15px !important;
    margin: 0px;
    margin-top: -50px !important;
    border: 0;
}

.portlet.tabbable .portlet-body {
    padding: 0px !important;
}

.sortable .portlet .portlet-header {
    cursor: move;
}

.sortable-placeholder {
    display: block;
    margin-top: 0px !important;
    margin-bottom: 25px !important;
    background-color: #f5f5f5;
    border: 1px dashed #0a819c;
}

    .sortable-placeholder * {
        visibility: hidden;
    }
/*.wait{position:relative; background:url("../../images/icons/loading.gif") center no-repeat !important;}*/
.family-tree-vertical li {
    margin: 0px 0;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0px 5px;
}

    .family-tree-vertical li:before {
        content: '';
        position: absolute;
        top: 0;
        width: 1px;
        height: 100%;
        right: auto;
        left: -20px;
        border-left: 1px solid #ccc;
        bottom: 50px;
    }

    .family-tree-vertical li:after {
        content: '';
        position: absolute;
        top: 30px;
        width: 25px;
        height: 20px;
        right: auto;
        left: -20px;
        border-top: 1px solid #ccc;
    }

    .family-tree-vertical li a {
        display: inline-block;
        border: 1px solid #ccc;
        padding: 5px 10px;
        text-decoration: none;
        color: #666;
        font-family: arial, verdana, tahoma;
        font-size: 11px;
        -webkit-border-radius: 5px !important;
        -moz-border-radius: 5px !important;
        border-radius: 5px !important;
    }

        .family-tree-vertical li a:hover, .family-tree-vertical li a:hover + ul li a {
            background: #c8e4f8;
            color: #000;
            border: 1px solid #94a0b4;
        }

    .family-tree-vertical li:last-child:before {
        height: 30px;
    }

.family-tree-vertical > ul > li::before, .family-tree-vertical > ul > li::after {
    border: 0;
}

.family-tree-vertical li a:hover + ul li::after, .family-tree-vertical li a:hover + ul li::before, .family-tree-vertical li a:hover + ul::before, .family-tree-vertical li a:hover + ul ul::before {
    border-color: #94a0b4;
}

.family-tree-horizontal ul {
    padding-top: 20px;
    position: relative;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

.family-tree-horizontal li {
    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

    .family-tree-horizontal li::before, .family-tree-horizontal li::after {
        content: '';
        position: absolute;
        top: 0;
        right: 50%;
        border-top: 1px solid #ccc;
        width: 50%;
        height: 20px;
    }

    .family-tree-horizontal li::after {
        right: auto;
        left: 50%;
        border-left: 1px solid #ccc;
    }

    .family-tree-horizontal li:only-child::after, .family-tree-horizontal li:only-child::before {
        display: none;
    }

    .family-tree-horizontal li:only-child {
        padding-top: 0;
    }

    .family-tree-horizontal li:first-child::before, .family-tree-horizontal li:last-child::after {
        border: 0 none;
    }

    .family-tree-horizontal li:last-child::before {
        border-right: 1px solid #ccc;
    }

.family-tree-horizontal ul ul::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #ccc;
    width: 0;
    height: 20px;
}

.family-tree-horizontal li a {
    border: 1px solid #ccc;
    padding: 5px 10px;
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

    .family-tree-horizontal li a:hover, .family-tree-horizontal li a:hover + ul li a {
        background: #c8e4f8;
        color: #000;
        border: 1px solid #94a0b4;
    }

        .family-tree-horizontal li a:hover + ul li::after, .family-tree-horizontal li a:hover + ul li::before, .family-tree-horizontal li a:hover + ul::before, .family-tree-horizontal li a:hover + ul ul::before {
            border-color: #94a0b4;
        }

.input-icon {
    position: relative;
}

    .input-icon input {
        padding-left: 33px !important;
        color: #999;
    }

    .input-icon i {
        color: #999;
        display: block;
        position: absolute;
        margin: 10px 2px 4px 10px;
        width: 16px;
        height: 16px;
        font-size: 16px;
        text-align: center;
    }

    .input-icon.right input {
        padding-left: 12px !important;
        padding-right: 33px !important;
    }

    .input-icon.right i {
        right: 8px;
        float: right;
    }

.has-success .input-icon i {
    color: #5cb85c;
}

.has-warning .input-icon i {
    color: #f0ad4e;
}

.has-error .input-icon i {
    color: #d9534f;
}

.rating {
    margin-bottom: 4px;
    font-size: 15px;
    line-height: 27px;
    color: #999;
}

    .rating label {
        display: block;
        float: right;
        height: 17px;
        margin-top: 5px;
        padding: 0 2px;
        font-size: 17px;
        line-height: 17px;
        cursor: pointer;
        color: #ccc;
        -ms-transition: color 0.3s;
        -moz-transition: color 0.3s;
        -webkit-transition: color 0.3s;
    }

        .rating label .fa-star:before {
            content: "\f005";
        }

.table thead tr th {
    background: #f1f1f1;
}

.table thead tr th, .table thead tr td {
    border-bottom: 0;
}

.table.table-sm {
    font-size: .875em;
}

.table.table-lg {
    font-size: 1.2em;
}

.table > input[type='text'] {
    font-weight: normal;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.table select {
    font-weight: normal;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.table.table-advanced thead tr th {
    border-bottom: 1px solid #ddd;
    background-color: #efefef;
}

.table.table-advanced thead.primary tr th {
    background: #0a819c;
    color: #fff;
}

.table.table-advanced thead.success tr th {
    background: #5cb85c;
    color: #fff;
}

.table.table-advanced thead.warning tr th {
    background: #f0ad4e;
    color: #fff;
}

.table.table-advanced thead.info tr th {
    background: #5bc0de;
    color: #fff;
}

.table.table-advanced thead.danger tr th {
    background: #d9534f;
    color: #fff;
}

.table.table-advanced thead.red tr th {
    background: #bf4346;
    color: #fff;
}

.table.table-advanced thead.orange tr th {
    background: #e9662c;
    color: #fff;
}

.table.table-advanced thead.green tr th {
    background: #488c6c;
    color: #fff;
}

.table.table-advanced thead.yellow tr th {
    background: #f2994b;
    color: #fff;
}

.table.table-advanced thead.blue tr th {
    background: #0a819c;
    color: #fff;
}

.table.table-advanced thead.pink tr th {
    background: #bf3773;
    color: #fff;
}

.table.table-advanced thead.violet tr th {
    background: #9351ad;
    color: #fff;
}

.table.table-advanced thead.grey tr th {
    background: #1b4283;
    color: #fff;
}

.table.table-advanced thead.dark tr th {
    background: #594857;
    color: #fff;
}

.table.table-advanced thead.white tr th {
    background: #fff;
    color: #999;
}

.table.table-advanced tfoot tr th {
    border-bottom: 0 !important;
    background: #efefef;
}

.table.table-advanced tfoot.primary tr th {
    background: #0a819c;
    color: #fff;
}

.table.table-advanced tfoot.success tr th {
    background: #5cb85c;
    color: #fff;
}

.table.table-advanced tfoot.warning tr th {
    background: #f0ad4e;
    color: #fff;
}

.table.table-advanced tfoot.info tr th {
    background: #5bc0de;
    color: #fff;
}

.table.table-advanced tfoot.danger tr th {
    background: #d9534f;
    color: #fff;
}

.table.table-advanced tfoot.red tr th {
    background: #bf4346;
    color: #fff;
}

.table.table-advanced tfoot.orange tr th {
    background: #e9662c;
    color: #fff;
}

.table.table-advanced tfoot.green tr th {
    background: #488c6c;
    color: #fff;
}

.table.table-advanced tfoot.yellow tr th {
    background: #f2994b;
    color: #fff;
}

.table.table-advanced tfoot.blue tr th {
    background: #0a819c;
    color: #fff;
}

.table.table-advanced tfoot.pink tr th {
    background: #bf3773;
    color: #fff;
}

.table.table-advanced tfoot.violet tr th {
    background: #9351ad;
    color: #fff;
}

.table.table-advanced tfoot.grey tr th {
    background: #1b4283;
    color: #fff;
}

.table.table-advanced tfoot.dark tr th {
    background: #594857;
    color: #fff;
}

.table.table-advanced tfoot.white tr th {
    background: #fff;
    color: #999;
}

.table.table-hover-color tbody tr:hover td:first-child {
    border-left: 4px solid #0a819c;
}

.table-actions {
    margin-bottom: 20px;
}

    .table-actions.bottom {
        margin-bottom: 0;
        margin-top: 20px;
    }

    .table-actions .pagination {
        margin: 0;
    }

.fixed-header {
    top: 0;
    position: fixed;
    width: auto;
    display: none;
    border: none;
    z-index: 999;
}

.page-header-breadcrumb {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.timeline-label {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.form-control {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-color: #e5e5e5;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    font-size: 12px;
}

.datetime-picker .form-control {
    height: 30px;
    padding: 5px 12px;
}

.datetime-picker i {
    margin: 7px 2px 5px 10px
}

.page-form .form-control {
    border-width: 0 0 1px;
}

label {
    font-weight: normal;
    margin-top: 6px;
}

.alert {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.breadcrumb {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.navbar {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.thumbnail {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
    border-color: #e5e5e5 !important;
}

.tooltip .tooltip-inner {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.popover {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    min-width: 450px !important;
    z-index: 1000000000 !important;
    border-width: 4px !important;
    border-style: solid !important;
    border-color: #000000 !important;
    right: 50px !important;
    --bs-popover-arrow-width: 3em;
    --bs-popover-arrow-height: 1.5em;

  }

  .popover-layout {
    left: 10px !important;
    top: -2px !important;
  }

    .popover.residentdot.right .popover-arrow {
        border-right-color: #000000;
    }

    .popover.residentdot.top .popover-arrow {
        border-top-color: #000000;
    }

    .popover.residentdot.bottom .popover-arrow {
        border-bottom-color: #000000;
    }

    .popover.residentdot.right > .popover-arrow:after {
        left: 8px;
        bottom: -10px;
    }

    .popover.residentdot.right > .popover-arrow {
        left: -16px !important;
        margin-top: -12px !important;
        border-right-color: #000000 !important;
    }

    .popover.residentdot > .popover-arrow {
        border-width: 12px;
    }


    .bs-popover-top > .popover-arrow {
        border-width: 12px;
        bottom: -12px;
        margin-left: -15px !important;
        border-top-color: #000000;
        border-bottom-width: 0;
      }

.popover .popover-arrow::before, .popover .popover-arrow::after {
  border-color: black !important;  /* this example changes the arrow to orange color... You can always replace with any color you choose. */
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}


.well {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-color: #e5e5e5 !important;
}

ul.todo-list li {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

ul.chats li.in .message {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

ul.chats li.out .message {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.cke_chrome {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

    .cke_chrome .cke_top {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        background-image: none;
    }

    .cke_chrome .cke_bottom {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        background-image: none;
    }

    .cke_chrome .cke_combo_button, .cke_chrome .cke_toolgroup {
        border-color: #e5e5e5;
        -webkit-border-radius: 5px !important;
        -moz-border-radius: 5px !important;
        border-radius: 5px !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        background-image: none;
    }

        .cke_chrome .cke_combo_button:hover, .cke_chrome .cke_toolgroup:hover, .cke_chrome .cke_combo_button:focus, .cke_chrome .cke_toolgroup:focus {
            background-image: none;
        }

    .cke_chrome a.cke_button_off:hover, .cke_chrome a.cke_button_off:focus, .cke_chrome a.cke_button_off:active, .cke_chrome a.cke_button_disabled:hover, .cke_chrome a.cke_button_disabled:focus, .cke_chrome a.cke_button_disabled:active, .cke_chrome .cke_combo_off a.cke_combo_button:hover, .cke_chrome .cke_combo_off a.cke_combo_button:focus {
        background-image: none;
    }

.md-editor > textarea {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

#toast-container > :hover {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

#toast-container div {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.has-switch {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
    border: 0;
}

    .has-switch span.switch-left, .has-switch span.switch-right, .has-switch label {
        text-shadow: none;
        box-shadow: none;
        background-image: none;
        border: 0;
    }

        .has-switch span.switch-left.switch-primary, .has-switch span.switch-right.switch-primary, .has-switch label.switch-primary {
            text-shadow: none;
            box-shadow: none;
            background-image: none;
            background: #0a819c !important;
        }

    .has-switch .switch-left {
        border-bottom-left-radius: 5px !important;
        border-top-left-radius: 5px !important;
    }

    .has-switch .switch-right {
        border-bottom-right-radius: 5px !important;
        border-top-right-radius: 5px !important;
    }

    .has-switch .switch-on label {
        border-bottom-right-radius: 5px !important;
        border-top-right-radius: 5px !important;
        background-color: #e5e5e5;
    }

    .has-switch .switch-off label {
        border-bottom-left-radius: 5px !important;
        border-top-left-radius: 5px !important;
        background-color: #e5e5e5;
    }

.slimScrollBar {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.clockface .outer, .clockface .inner {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

    .clockface .inner.active, .clockface .inner.active:hover, .clockface .outer.active, .clockface .outer.active:hover {
        text-shadow: none;
        background-image: none;
    }

        .clockface .inner.active:hover, .clockface .inner.active:hover:hover, .clockface .inner.active:active, .clockface .inner.active:hover:active, .clockface .inner.active.active, .clockface .inner.active:hover.active, .clockface .inner.active.disabled, .clockface .inner.active:hover.disabled, .clockface .inner.active[disabled], .clockface .inner.active:hover[disabled] {
            background: #0a819c;
        }

        .clockface .outer.active:hover, .clockface .outer.active:hover:hover, .clockface .outer.active:active, .clockface .outer.active:hover:active, .clockface .outer.active.active, .clockface .outer.active:hover.active, .clockface .outer.active.disabled, .clockface .outer.active:hover.disabled, .clockface .outer.active[disabled], .clockface .outer.active:hover[disabled] {
            background: #5cb85c;
        }

.bootstrap-datetimepicker-widget td span {
    height: auto;
    line-height: normal;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover {
    background-image: none;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.bootstrap-datetimepicker-widget td, .bootstrap-datetimepicker-widget th {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.datepicker td, .datepicker th {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.datepicker table tr td.selected, .datepicker table tr td.selected:hover, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover {
    background-image: none;
    text-shadow: none;
}

.datepicker table tr td span.active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover {
    background-image: none;
    text-shadow: none;
}

.datepicker table tr td span {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.datepicker {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.daterangepicker .ranges li {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
    color: #999;
}

    .daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
        background: #0a819c;
        border: 1px solid #0a819c;
    }

.daterangepicker .ranges .input-mini, .daterangepicker td, .daterangepicker th, .daterangepicker .calendar-date {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.fc-state-default {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-image: none;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
    border: 1px solid #e5e5e5;
    text-shadow: none;
    color: #999;
}

.page-form {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.media img.media-object {
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
}

.dd .dd-list .dd-item .dd-handle, .dd .dd-list .dd-item .dd3-content {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
    border-color: #e5e5e5 !important;
}

    .dd .dd-list .dd-item .dd-handle.dd3-handle, .dd .dd-list .dd-item .dd3-content.dd3-handle {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

.jquery-notific8-notification {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.wizard > .steps a, .wizard > .steps a:hover, .wizard > .steps a:active {
    padding: 0.5em 1em;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.wizard > .actions a, .wizard > .actions a:hover, .wizard > .actions a:active {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

#theme-setting .btn-theme-setting {
    border-bottom-left-radius: 10px !important;
    border-top-left-radius: 10px !important;
}

#theme-setting .content-theme-setting ul#list-color li {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

ul.ul-edit.navbar.nav-tabs li a {
    border-radius: 4px !important;
}

#responsive .timeline-panel {
    -webkit-border-radius: 7px !important;
    -moz-border-radius: 7px !important;
    border-radius: 7px !important;
}

#one-column .message-item:after {
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
}

#one-column .message-inner {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

#one-column .avatar img {
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
}

.add-user-blk ul, #add-user-blk ul li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.add-user-blk ul {
    display: table;
    width: 100%;
    padding: 15px;
    background: #f9f9f9;
    margin-bottom: 15px;
    border-radius: 4px;
}

    .add-user-blk ul li {
        display: table-cell;
        vertical-align: middle;
    }

        .add-user-blk ul li span {
            margin-right: 15px;
            display: block;
        }

        .add-user-blk ul li:last-child {
            width: 91px;
        }

.dataTables_filter {
    display: none;
}

.dataTables_info {
    padding: 7px 0;
}

.dataTable .sorting_disabled {
    background: #f1f1f1;
}

.dataTable .sorting {
    background: #f1f1f1 url(../images/sort_both.png) no-repeat right center;
    outline: none;
    cursor: pointer;
}

.dataTable .sorting_asc {
    background: #f1f1f1 url(../images/sort_asc.png) no-repeat right center;
    outline: none;
    cursor: pointer;
}

.dataTable .sorting_desc {
    background: #f1f1f1 url(../images/sort_desc.png) no-repeat right center;
    outline: none;
    cursor: pointer;
}

.dataTables_wrapper {
}

    .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate {
        display: inline-block;
        width: 50%;
        vertical-align: middle;
    }

.center-block {
    float: none;
}

.ui-widget {
    font-size: 12px;
}

.form-control:focus {
    border-color: #999;
}

.navbar-header #change-product .dropdown-toggle {
    background: none;
    padding: 4px 0;
    text-align: center;
}

#change-product .product-list {
    background: #fff;
    width: 250px;
}

    #change-product .product-list a {
        display: block;
        text-align: center;
    }

.page-title-group .ngSelect {
    display: -ms-inline-grid;
    vertical-align: middle;
}

.multiselect-wrapper {
    position: relative;
    text-align: left;
}

    .multiselect-wrapper .btn {
        text-align: left;
        height: 30px;
        border-radius: 0 !important;
        background: #fff;
        color: #555;
        border-color: #e5e5e5;
        text-transform: none;
        letter-spacing: normal;
        font-size: 12px;
    }

    .multiselect-wrapper .btn-outline-secondary:hover, .multiselect-wrapper .btn-outline-secondary:focus, .multiselect-wrapper .btn-outline-secondary:active, .multiselect-wrapper .btn-outline-secondary.active, .multiselect-wrapper .open .dropdown-toggle.btn-outline-secondary {
        background: #fff;
        color: #555;
        border-color: #999;
    }

.btn-group.open .dropdown-toggle {
    box-shadow: none;
}

.multiselect-wrapper .multiselect-container {
    width: 100%;
    max-height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
}

.multiselect-wrapper .btn .caret {
    /*  DEP
    float:right;
    margin-top:6px;
*/
}

.multiselect-wrapper .dropdown-menu {
    background: #fff;
}

    .multiselect-wrapper .dropdown-menu li a {
        border-bottom: 0;
        padding: 2px 10px;
        background: #fff;
        color: #555;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        cursor: pointer;
    }

        .multiselect-wrapper .dropdown-menu li a:hover {
            background: #3399ff !important;
            color: #fff !important;
            border: 0 !important;
            border-radius: 0;
            -moz-border-radius: 0;
            -webkit-border-radius: 0;
        }

.multiselect-container > li > a > label {
    padding-left: 30px;
}

#add-filter .mb-3 {
    margin-bottom: 10px;
}

#add-filter .panel > .panel-heading {
    font-size: 14px;
    font-weight: bold;
}

#add-filter .panel > .filter-header {
    padding: 7px 10px !important;
    font-size: 14px;
    font-weight: bold;
    line-height: 26px;
}

#add-filter .panel-body {
    padding: 10px;
}

.table > thead > tr > th {
    vertical-align: middle;
}

.draggablePanelList .panel-heading {
    cursor: move;
}

#sidebar #menu-toggle {
    position: absolute;
    right: 0;
    z-index: 2;
    top: 5px;
    color: rgba(0, 0, 0, 0.658823);
    width: 35px;
    height: 48px;
    display: block;
    text-align: center;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1f1f1f+0,1f1f1f+100&0+0,1+35 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001f1f1f', endColorstr='#1f1f1f',GradientType=1 ); /* IE6-9 */
}

    #sidebar #menu-toggle:hover {
        color: #0074bd;
    }

    #sidebar #menu-toggle i {
        font-size: 25px;
        padding: 17px 0px;
    }

.help i {
    font-size: 30px;
    font-weight: normal;
}

.facility-view {
    display: inline-block;
}

.facility-view-units {
    display: inline-block;
    padding-left: 40px;
}
    .facility-view input {
        display: inline-block;
        vertical-align: top;
    }

.page-title-breadcrumb .pull-right .facility-view {
    line-height: 37px;
}

.highcharts-contextmenu hr, .highcharts-contextmenu div div:nth-child(3), .highcharts-contextmenu div div:nth-child(4), .highcharts-contextmenu div div:nth-child(6) {
    display: none;
}

.user-panel{
    padding: 20px 15px 0px;

}
.user-panel .searchbox{
    top: 20px !important;
}

.feedbackbtn {
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 4px 4px 0 0;
    z-index: 999;
    overflow: hidden;
}

    .feedbackbtn.open {
        box-shadow: 0px 0px 5px #ddd;
        -moz-box-shadow: 0px 0px 5px #ddd;
        -webkit-box-shadow: 0px 0px 5px #ddd;
    }

    .feedbackbtn .dropdown-menu {
        top: auto;
        bottom: 100%;
        left: auto;
        right: 0;
    }

    .feedbackbtn h3 {
        font-size: 12px;
        font-weight: bold;
        background: #0074bd;
        color: #fff;
        padding: 7px 15px;
        margin: 0;
        cursor: pointer;
    }

    .feedbackbtn.open h3 {
        background: #f46f34;
    }

    .feedbackbtn h3 a {
        color: #fff;
    }

    .feedbackbtn ul, .feedbackbtn ul li {
        margin: 0;
        list-style: none;
    }

    .feedbackbtn .feedback-form, .feedbackbtn ul {
        background: #fff;
        padding: 0px;
        width: 225px;
        position: relative;
    }

.feedback-list-item {
    padding: 5px 10px;
}

    .feedback-list-item:first-of-type {
        padding-top: 10px;
    }

    .feedback-list-item:last-of-type {
        padding-bottom: 10px;
    }

.feedbackbtn ul li a {
    background: #e7e7e7;
    color: #444;
    display: block;
    padding: 10px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
}

    .feedbackbtn ul li a:hover {
        /* !important needed to overide #footer styles */
        background: #0074bd !important;
        color: #fff !important;
    }

.feedbackbtn .feedback-form .btn {
    width: 100%;
}
/*.welcome-msg{position:absolute; top:0; left:0; width:100%; min-height:100%; height:100%; background:rgba(0, 0, 0, .7); color:#fff;}*/
.welcome-msg {
    background: rgba(0, 0, 0, .7);
    color: #fff;
}

    .welcome-msg h4 {
        margin: 0;
        padding: 15px;
        font-size: 16px;
        text-align: center;
    }

#modal-help .carousel .item {
    min-height: 300px;
}

#modal-help .carousel {
    padding: 0 50px;
}

#modal-help .carousel-control {
    background: none;
    width: auto;
    top: 147px;
    color: #333;
}

    #modal-help .carousel-control:hover {
        color: #f46f34;
    }

#modal-help .carousel h3 {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 20px;
    padding-bottom: 10px;
    color: #f46f34;
}

#modal-help .carousel .item-img {
    min-height: 230px;
    display: table;
    width: 100%;
}

    #modal-help .carousel .item-img span {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
    }

#modal-help .carousel-indicators {
    background-color: transparent;
    bottom: -87px;
}

    #modal-help .carousel-indicators li {
        background-color: #ccc;
        border: 0;
    }

        #modal-help .carousel-indicators li:hover, #modal-help .carousel-indicators li.active {
            background-color: #f46f34;
        }

.listing li {
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    margin: 0;
    padding: 8px 5px 8px 10px;
}

.listing {
    list-style: none;
    margin: 0;
    padding: 0;
}

    .listing li:hover {
        background: #fafafa;
    }

    .listing li {
        border-bottom: 1px solid #ccc;
        margin: 0;
        padding: 8px 5px 8px 10px;
        cursor: pointer;
    }

        .listing li:last-child {
            border-bottom: none;
            border-radius: 0 0 5px 5px;
        }

.the-box {
    padding: 15px;
    margin-bottom: 20px;
    position: relative;
    border-radius: 4px;
}

    .the-box h3 {
        font-size: 14px;
        text-transform: uppercase;
        margin: 0;
    }

    .the-box h1 span {
        font-size: 20px;
    }

.draggablePanelList .the-box h3 {
    cursor: move;
}

.chart-value {
    font-size: 20px;
    color: #fff;
}

#top-users .panel-body, #top-extractions .panel-body {
    padding: 0;
}

.piechart-container .the-box {
    padding: 15px 5px;
}

#overall-com-level text {
    fill: #ffffff !important;
    color: #ffffff !important;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-outline-secondary {
    border: 0;
}

.maxHeightforDashboard {
    overflow-y: auto;
    max-height: 75vh;
}

.scroll {
    max-height: 72vh;
    overflow-y: auto;
}

    .scroll::-webkit-scrollbar {
        width: 1px;
        background-color: #F5F5F5;
        cursor: grab;
    }

    .scroll::-webkit-scrollbar-thumb {
        background-color: #CEC8C8;
        border: 1px solid #F9E6E6;
    }

ul.datetime-picker-dropdown span button.btn {
    color: #333 !important;
    background-color: #fff !important;
    border-color: #ccc !important;
    text-transform: none !important;
}

ul.datetime-picker-dropdown li:last-child span:first-child {
    margin-right: 0 !important;
}

.reportTitle {
    /* DEP
    font-size: 12px;
    font-weight: bold;*/
}

.rtms-card .noDataDiv {
    margin-top: 40px !important;
    font-weight: bold;
    font-size: 13px;
}

.noDataGrid {
    margin-top: 15px;
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 13px;
}
.pWSideBarHeight {
    height: calc(100% - 50px);
}

.bold {
    font-weight: bold;
}
/* Frozne table header stylizing  */
.table-container {
    position: relative;
    padding-top: 43px;
    border: 1px solid grey;
    background: #e2e2e2;
    height: 100%
}

    .table-container.multi {
        padding-top: 60px;
        background-color: #eaeaea !important;
    }

    .table-container .financialdetail_scroll {
        height: calc(100vh - 240);
        background: white;
    }

        .table-container .financialdetail_scroll .ps-initial {
            position: initial !important;
        }

        .table-container .financialdetail_scroll .field-label {
            position: absolute;
            top: 0px;
        }

        .table-container .financialdetail_scroll .table-label {
            position: absolute;
            top: 0px;
            height: 60px;
        }

        .table-container .financialdetail_scroll .table-label-sec {
            position: absolute;
            top: 30px;
            height: 30px;
            border-left: 1px solid #c7c7c7;
            margin-left: -1px;
            padding: 7px 3px;
        }

        .table-container .financialdetail_scroll .table-label,
        .table-container .financialdetail_scroll .field-label {
            border-left: 1px solid #c7c7c7;
            margin-left: -1px;
        }

            .table-container .financialdetail_scroll .field-label.nb,
            .table-container .financialdetail_scroll table th:first-child .table-label {
                border-left: 0px solid #c7c7c7;
            }

        .table-container .financialdetail_scroll .field-label {
            border-left: 1px solid #c7c7c7;
            margin-left: -1px;
            height: 42px;
            margin-top: 0px;
        }

        .table-container .financialdetail_scroll table .field-label {
            border-left: 0px solid #c7c7c7;
        }

    .table-container .table:not(.ignore-table-limits) > thead > tr > th:not(.narrow-column):not(.wide-column) {
        max-width: 95px;
        /*min-height: 75px;*/
        min-width: 85px;
    }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .fixed-height-report-adl .fh-report-table thead th:first-child {
        height: 32px;
    }
}


.table-container.multi .table > thead > tr > th {
    max-width: initial;
    min-width: initial;
}

    @supports (-ms-ime-align:auto) {
        /* CSS styles for Edge goes here */
        .fixed-height-report-adl .fh-report-table thead th:first-child {
            height: 32px;
        }
    }
    .table-container.multi .table > thead > tr > th .table-label.two-line {
        width: 45px;
        padding-top: 10px;
        word-wrap: break-word;
    }

.table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(5),
.table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(6),
.table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(7),
.table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(8) {
    width: 52px;
}

.table-container.multi .financialdetail_scroll .table > thead > tr > th {
    width: 55px;
}

.table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(8) .table-label,
.table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(7) .table-label,
.table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(6) .table-label,
.table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(5) .table-label {
    border-bottom: 1px solid #c7c7c7;
    width: 94px;
    height: 30px;
}

.table-container.multi tbody > tr > td,
.table-container.multi tbody > tr > td:first-child,
.table-container.multi tbody > tr > td:last-child {
    width: 10px;
}

    .table-container.multi tbody > tr > td:nth-child(5),
    .table-container.multi tbody > tr > td:nth-child(6),
    .table-container.multi tbody > tr > td:nth-child(7),
    .table-container.multi tbody > tr > td:nth-child(8),
    .table-container.multi tbody > tr > td:nth-child(9),
    .table-container.multi tbody > tr > td:nth-child(10),
    .table-container.multi tbody > tr > td:nth-child(11),
    .table-container.multi tbody > tr > td:nth-child(12) {
        width: 30px;
    }

.grid-table {
    padding: 0px 15px;
    border-bottom: 1px solid #ececec;
}

    .grid-table > div {
    }

.th-ph-60 {
    padding-top: 60px;
}

.th-one-line {
    padding: 20px;
}

.th-two-line {
    padding: 10px 5px;
}

.th-three-line {
    padding: 5px;
}

.th-mt-20 {
    margin-top: -20px !important;
}

i.fa.fa-sort-asc, i.fa.fa-sort-desc {
    padding-left: 10px;
}

.dh-two-line {
    padding: 5px 10px;
}

.dh-one-line {
    padding: 10px;
}

@media (min-width:968px) and (max-width: 1200px) {
    .table div {
        font-size: 7px;
    }

    .table-container.multi .table > thead > tr > th .table-label.two-line {
        width: 25px;
    }

    .table td {
        font-size: 7px;
    }

    .table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(8) .table-label,
    .table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(7) .table-label,
    .table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(6) .table-label,
    .table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(5) .table-label {
        width: 57px;
    }

    .table-container .financialdetail_scroll .table-label-sec {
        padding: 7px 0px;
    }

    .table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(12) .table-label {
        padding-top: 3px;
    }

    .table i, .reportSmallFont .table i {
         font-size: 7px;
         line-height: 7px;
    }
}

@media (min-width:1201px) and (max-width: 1400px) {
    .table div {
        font-size: 9px;
    }

    .table-container.multi .table > thead > tr > th .table-label.two-line {
        width: 34px;
    }

    .table td {
        font-size: 9px;
    }

    .table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(8) .table-label,
    .table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(7) .table-label,
    .table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(6) .table-label,
    .table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(5) .table-label {
        width: 70px;
    }

    .table-container .financialdetail_scroll .table-label-sec {
        padding: 7px 0px;
    }

    .table-container.multi .financialdetail_scroll .table > thead > tr:first-child > th:nth-child(12) .table-label {
        padding-top: 3px;
    }

    .table i, .reportSmallFont .table i {
         font-size: 9px;
         line-height: 9px;
    }
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
    .container {
        max-width: 1000px;
    }

    /* Remove the padding we set earlier */
    .header,
    .marketing,
    .footer {
        padding-left: 0;
        padding-right: 0;
    }
    /* Space out the masthead */
    .header {
        margin-bottom: 30px;
    }
    /* Remove the bottom border on the jumbotron for visual effect */
    .jumbotron {
        border-bottom: 0;
    }

    .gridStyle {
        border: 1px solid rgb(212,212,212);
        width: 100px;
        height: 300px
    }
}

.divlogin {
    margin-bottom: 0px;
    bottom: 0;
    height: 100vh;
}

.modal-backdrop {
    opacity: .95 !important;
    z-index: 1000000 !important;
}

.modal.fade {
    z-index: 10000000 !important;
}

.no-left-right-padding {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.card-panel .number-widget-card {
    display: block;
    width: 100%;
}
.card-panel .noContent {
    margin: 0;
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%) !important;
}
.card-panel .number-widget-card .noDataDiv div{
    margin: 0;top: 50%;position: absolute;left: 50%;transform: translate(-50%,-50%)!important;
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #666666;
    padding-top: 16px;
    position: relative;

}
.citation {
    position: absolute;
    bottom: -2px;
    right: 2px;
}
.flip-text{
    position: absolute;
    bottom: -2px;
    left: 2px;;
    z-index: 1000;
}
.no-wrap {
    white-space: nowrap;
}

.sorted {
    background-color: #efefef;
}

.rtmsgrid.panel-heading {
    padding: 5px 15px !important;
}

.rtmsgrid.panel-body {
    padding-top: 0px !important;
}

.rtmsgrid.pagination {
    vertical-align: middle;
}

    .rtmsgrid.pagination .current {
        padding-left: 5px;
        margin-top: 4px;
        float: left;
    }

    .rtmsgrid.pagination .child {
        padding-left: 3px;
        margin-top: 4px;
        float: left;
    }

.inbox-group-header {
    font-weight: bold;
    font-size: 16px;
    background-color: white;
}

.inbox-item {
    font-size: 12px;
    background-color: white;
}

.inbox-item-read {
    font-weight: bold;
}

.inbox-item-unread {
    font-weight:lighter;
}

.detail-button {
    float: left;
    padding-right: 5px;
}

/* Accordian start */
.collapsible-item {
    margin-bottom: 10px;
}

    .collapsible-item .title {
        padding: 10px;
        background-color: #f5f5f5;
        background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
        background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
        background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
        background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
        border: 1px solid #ccc;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        cursor: pointer;
    }

        .collapsible-item .title .icon {
            float: right;
            height: 20px;
            width: 20px;
            font-size: 19px !important;
            padding-right: 1px;
        }

        .collapsible-item .title .iconleft {
            float: left !important;
        }

        .collapsible-item .title.disabled {
            background: #eee;
            color: #999;
            cursor: text;
        }

    .collapsible-item > .body {
        position: relative;
        top: -4px;
        max-height: 0;
        overflow: hidden;
        border: 1px solid #ccc;
        border-top: 0;
        z-index: -1;
        -webkit-transition: max-height 0.5s ease;
        -moz-transition: max-height 0.5s ease;
        -o-transition: max-height 0.5s ease;
        transition: max-height 0.5s ease;
    }

        .collapsible-item > .body .content {
            padding: 20px 15px 5px 15px;
        }

    .collapsible-item.open > .body {
        max-height: 1000px;
        z-index: 1;
    }

    .collapsible-item.open .title {
        -webkit-border-radius: 5px 5px 0 0;
        -moz-border-radius: 5px 5px 0 0;
        border-radius: 5px 5px 0 0;
    }

    .illogical-reason {
        background-color: #E9573F;
        color: #ffffff;
    }

    .adl-increase {
       background-color: #c5d79b;
    }

    .adl-decrease {
        background-color: #fabf8f;
    }
    /* End Accordian */


    .citation .card-info, .flip-text .card-info {
        background-color: transparent;
    }

    .panel-short-card {
        min-height: 135px !important;
        max-height: 175px !important;
    }

    .resident-name-size {
        font-size: 16px;
    }

    .resident-social-services-row-padding {
        padding-top: 45px;
    }

    .resident-detail-row-padding {
        padding-top: 15px;
    }

    .resident-detail-card {
      max-height:110px;
      overflow-y:auto;
      overflow-x: hidden;
    }

    .resident-social-services-card {
      max-height:200px;
      overflow-y:auto;
      overflow-x: hidden;
    }
    .header-selector {
      white-space:nowrap;
    }

    .header-selector-size {
      width: 200px;
    }

    .header-selector-view {
      display: inline-block;
        margin: 5px 0 5px 30px;
    }

    .resident-grid-padding {
      padding: 5px;
    }
    .m-l-3 {
      margin-left: 3px;
    }

    .no-select{
      -webkit-user-select: none; /* Chrome/Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+ */

      /* Rules below not implemented in browsers yet */
      -o-user-select: none;
      user-select: none;
    }
.fullscreen-done .citation, .fullscreen-done .flip-text, .fullscreen-done .export-button,
.info-modal .citation, .info-modal .flip-text, .info-modal .export-button {
    display: none !important;
}

.chart-container-wrapper cdk-virtual-scroll-viewport {
    height: 180px !important;
}

.content-middle {
    margin: 0;
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

.custom {
  font-size: 10px !important;
}

  .ng-select.custom ::ng-deep .ng-select-container  {
      border:1px solid #ccc!important;
      min-height:20px !important;
      height:30px !important;
      max-height:30px !important;
      text-align: center !important;
  }

  .ng-select.custom ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      white-space: pre-wrap !important;
  }

.btn.btn-small {
    font-size: 9px !important;
    }
    .btn.btn-small i{
        font-size: 10px !important;
        }

input[type="checkbox"] {
    vertical-align: text-bottom;
}
input[type="checkbox"] + span {
    padding-left: 2px;
    vertical-align: middle;
}


@media (max-width: 768px){
    .pWSideBarHeight {
        height: auto !important;
    }
}

.resident-indicator-modal .modal-dialog {
  width: 800px;
  max-width:100%;
}

.resident-vitals-modal .modal-dialog {
    width: 350px;
  }

  .resident-vitals-modal .modal-dialog .modal-content {
    min-height: 250px !important;
    max-height: 500px !important;
  }

  @media (max-width:460px) {
    .resident-vitals-modal .modal-dialog {
        max-width : 75% !important;
    }
}

.resident-labs-modal .modal-dialog{
    width: 450px;
  }

  @media (max-width:460px) {
    .resident-labs-modal .modal-dialog {
        max-width : 75% !important;
    }
}

.grecaptcha-badge { opacity:0;}
.p-datepicker table th {
    text-align: center;
}
.close-btn {
    float:right;
    font-size: 20px;
    margin-left: 10px;
}
.inbox-item{
    font-size: 16px;
    font-weight: bold
}

.inbox-accordian{
    padding-left: 16px;
}
.titleContent {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.p-datepicker {
    width: 250px !important;
}

input:focus.search-tag-resident, input.search-tag-resident {
    outline: none;
    border: none;
    margin-left: 3px;
}

.ng-select.tag-resident .ng-select-container {
    border-radius: 0px !important;
    height: 34px !important;
    max-height: 34px !important;
}

.text-right-padding {
    text-align: right;
    padding-right: 15%;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    flex-wrap: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    display: inline !important;
}

/* Changes from Bootstrap 3 to 5 */
label {
  margin-top: 6px;
  margin-bottom: 5px;
}

.form-control::placeholder {
  color: #999;
  opacity: 1;
}

.form-control {
  display:block;
  width:100%;
  height:34px;
  padding:6px 12px;
  font-size:14px;
  line-height:1.42857143;
  color:#555;
  background-color:#fff;
  background-image:none;
  border:1px solid #ccc;
  border-radius:4px;
  box-shadow:inset 0 1px 1px #00000013;
  transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s
}

.btn {
  padding:6px 12px;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.popover {
  max-width:276px;
  padding:1px;
  font-family:Helvetica Neue,Helvetica,Arial,sans-serif;
  font-style:normal;
  font-weight:400;
  line-height:1.42857143;
  line-break:auto;
  text-align:left;
  text-align:start;
  text-decoration:none;
  text-shadow:none;
  text-transform:none;
  letter-spacing:normal;
  word-break:normal;
  word-spacing:normal;
  word-wrap:normal;
  white-space:normal;
  font-size:14px;
  background-color:#fff;
  background-clip:padding-box;
  border:1px solid #ccc;
  border:1px solid rgba(0,0,0,.2);
  border-radius:6px;
  box-shadow:0 5px 10px #0003
}
.popover.top {
  margin-top:-10px
}
.popover.right {
  margin-left:10px
}
.popover.bottom {
  margin-top:10px
}
.popover.left {
  margin-left:-10px
}
.popover>.arrow {
  border-width:11px
}
.popover>.arrow,
.popover>.arrow:after {
  position:absolute;
  display:block;
  width:0;
  height:0;
  border-color:transparent;
  border-style:solid
}
.popover>.arrow:after {
  content:"";
  border-width:10px
}
.popover.top>.arrow {
  bottom:-11px;
  left:50%;
  margin-left:-11px;
  border-top-color:#999;
  border-top-color:#00000040;
  border-bottom-width:0
}
.popover.top>.arrow:after {
  bottom:1px;
  margin-left:-10px;
  content:" ";
  border-top-color:#fff;
  border-bottom-width:0
}
.popover.right>.arrow {
  top:50%;
  left:-11px;
  margin-top:-11px;
  border-right-color:#999;
  border-right-color:#00000040;
  border-left-width:0
}
.popover.right>.arrow:after {
  bottom:-10px;
  left:1px;
  content:" ";
  border-right-color:#fff;
  border-left-width:0
}
.popover.bottom>.arrow {
  top:-11px;
  left:50%;
  margin-left:-11px;
  border-top-width:0;
  border-bottom-color:#999;
  border-bottom-color:#00000040
}
.popover.bottom>.arrow:after {
  top:1px;
  margin-left:-10px;
  content:" ";
  border-top-width:0;
  border-bottom-color:#fff
}
.popover.left>.arrow {
  top:50%;
  right:-11px;
  margin-top:-11px;
  border-right-width:0;
  border-left-color:#999;
  border-left-color:#00000040
}
.popover.left>.arrow:after {
  right:1px;
  bottom:-10px;
  content:" ";
  border-right-width:0;
  border-left-color:#fff
}
.popover-title {
  padding:8px 14px;
  margin:0;
  font-size:14px;
  background-color:#f7f7f7;
  border-bottom:1px solid #ebebeb;
  border-radius:5px 5px 0 0
}
.popover-content {
  padding:9px 14px
}
.popover-body {
  padding: 0
}

textarea.form-control {
  height: auto;
}

/* Remove Bootstrap Dropdown Arrow*/
.dropdown-toggle::after {
  content: none;
}
