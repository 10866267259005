@font-face {
    font-family: 'icomoon';
    src:  url('assets/fonts/icomoon.eot?jqjaqc');
    src:  url('assets/fonts/icomoon.eot?jqjaqc#iefix') format('embedded-opentype'),
      url('assets/fonts/icomoon.ttf?jqjaqc') format('truetype'),
      url('assets/fonts/icomoon.woff?jqjaqc') format('woff'),
      url('assets/fonts/icomoon.svg?jqjaqc#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  .icomoon {
    &[class^="icon-"], &[class*=" icon-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
      
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      
      &.icon-patient:before {
        content: "\70";
      }

      &.icon-enterprise:before {
        content: "\6f";
      }    
  }
